import { combineReducers } from 'redux'

import themes from './themes'
import audioPlayer from './audioPlayer'
import recent from './recent'
import favorite from './favorite'

export default combineReducers({
  audioPlayer,
  favorite,
  themes,
  recent
})
