import { ADD_FAVORITE, REMOVE_FAVORITE } from '../actions/favorite'

const initialState = {
  artists: [],
  audios: []
}
const addFavorite = (state, payload) => {
  const newFavorites = [payload.data, ...state[payload.type]]
  return {
    ...state,
    [payload.type]: newFavorites
  }
}

const removeFavorite = (state, payload) => {
  const newFavorites = state[payload.type].filter(
    data => data.id !== payload.data.id
  )
  return {
    ...state,
    [payload.type]: newFavorites
  }
}

export default (state = initialState, action) => {
  switch (action.type) {
    case ADD_FAVORITE:
      return addFavorite(state, action.payload)
    case REMOVE_FAVORITE:
      return removeFavorite(state, action.payload)
    default:
      return state
  }
}
