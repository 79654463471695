import React, { useContext, useEffect, useState } from 'react'
import styled, { ThemeContext } from 'styled-components'
import { useParams } from 'react-router-dom'
import { useQuery } from '@apollo/client'

import { BigArtistText } from '../../components/texts'
import { artists } from '../../graphql/queries'
import getLocale from '../../utils/getLocale'
import Playlist from '../../components/playlist'
import { useTranslation } from 'react-i18next'
import HeaderBack from '../../components/headerBack'
import { AiOutlineHeart, AiFillHeart } from 'react-icons/ai'
import artistDefault from '../../assets/images/artistDefault.png'
import { useDispatch, useSelector } from 'react-redux'
import addOrRemoveAudioToFavorite from '../../utils/addOrRemoveAudioToFavorite'
import getRandomColor from '../../utils/getRandomColor'
import tracker from '../../utils/tracker'
import Loader from '../../components/loader'

const Artist = () => {
  const { t } = useTranslation()
  const { id } = useParams()
  const theme = useContext(ThemeContext)
  const [isFavorite, setIsFavorite] = useState(false)
  const favoriteArtist = useSelector(state => state.favorite.artists)
  const dispatch = useDispatch()
  useEffect(() => {
    const checkFavorite = favoriteArtist.filter(fav => fav.id === id)
    if (checkFavorite[0]) {
      setIsFavorite(true)
    } else {
      setIsFavorite(false)
    }
    tracker(window.location.pathname)
  }, [])

  const {
    loading: artistLoading,
    error: artistError,
    data: artistData
  } = useQuery(artists.GET_ONE_ARTIST, {
    variables: { locale: getLocale(), id, albumName: t('artist.quranSearch') }
  })

  if (artistLoading) return <Loader />
  if (artistError) return <p>error</p>
  if (!artistData?.artists[0]) return <p>error</p>

  const [artist] = artistData.artists

  const srcImage = artist?.picture?.formats?.thumbnail.url
    ? artist?.picture?.formats?.thumbnail.url
    : artistDefault

  return (
    <Component backgroundColor={getRandomColor()}>
      <HeaderBack label={artist.name}></HeaderBack>
      <HeaderArtist>
        <AvatarContainer>
          <Avatar src={srcImage}></Avatar>
        </AvatarContainer>
        <BigArtistText>{artist.name}</BigArtistText>
      </HeaderArtist>
      <OptionsContainer>
        <IconTouchable
          onClick={() =>
            addOrRemoveAudioToFavorite({
              data: { id, name: artist?.name, picture: artist?.picture },
              isFavorite,
              setIsFavorite,
              dispatch,
              type: 'artists'
            })
          }
        >
          {isFavorite ? (
            <AiFillHeart color={theme.iconColor} size={24}></AiFillHeart>
          ) : (
            <AiOutlineHeart color={theme.iconColor} size={24}></AiOutlineHeart>
          )}
        </IconTouchable>
      </OptionsContainer>
      <ContentContainer>
        <Playlist
          picture={artist?.albums[0]?.picture}
          label={artist?.albums[0]?.name}
          audios={artist?.albums[0]?.audios}
        ></Playlist>
      </ContentContainer>
    </Component>
  )
}

const IconTouchable = styled.div`
  padding: 3px 12px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
`

const OptionsContainer = styled.div`
  justify-content: flex-end;
  display: flex;
`

const ContentContainer = styled.div`
  overflow: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  ::-webkit-scrollbar {
    display: none;
  }
`

const AvatarContainer = styled.div`
  margin-bottom: 12px;
`

const HeaderArtist = styled.div`
  box-sizing: border-box;
  padding: 12px;
  width: 100%;
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const Avatar = styled.img`
  width: 200px;
  height: 200px;
  object-fit: cover;
  border-radius: 100px;
  box-shadow: 0px 0px 40px #000000a0;
`

const Component = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  background: ${props =>
    `linear-gradient(${props.backgroundColor}, ${props.theme.artistSecondary} 4%)`};
`

export default Artist
