import React from 'react'
import styled from 'styled-components'
import './loader.css'

const Loader = () => {
  return (
    <Container>
      <div className='lds-ring'>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </Container>
  )
}

const Container = styled.div`
  position: absolute;
  left: 50%;
  margin-left: -40px;
  top: 40%;
  margin-top: -40px;
`

export default Loader
