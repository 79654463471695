import React from 'react'
import styled from 'styled-components'
import { useContext } from 'react'
import { ThemeContext } from 'styled-components'
import { AiFillHome, AiOutlineSearch, AiOutlineHeart } from 'react-icons/ai'

import { NavButton } from '../buttons'
import devices from '../../config/devices'
import useWindowSize from '../../utils/useWindowSize'
import { useTranslation } from 'react-i18next'
import handleNavigation from '../../utils/handleNavigation'

import MusliminPlayLogo from '../../assets/images/musliminPlayLogo.png'

const NavBar = () => {
  const currentTheme = useContext(ThemeContext)
  const windowSize = useWindowSize()
  const [t] = useTranslation()

  return (
    <Container height={windowSize.height}>
      <LogoContainer>
        <Logo src={MusliminPlayLogo}></Logo>
      </LogoContainer>
      <NavButton
        icon={AiFillHome}
        iconColor={currentTheme.iconColor}
        iconSize={24}
        label={t('navBar.home')}
        onClick={() => handleNavigation('/')}
      ></NavButton>
      <NavButton
        icon={AiOutlineSearch}
        iconColor={currentTheme.iconColor}
        iconSize={24}
        label={t('navBar.search')}
        onClick={() => handleNavigation('/search')}
      ></NavButton>
      <NavButton
        icon={AiOutlineHeart}
        iconColor={currentTheme.iconColor}
        iconSize={24}
        label={t('navBar.favorite')}
        onClick={() => handleNavigation('/favorite')}
      ></NavButton>
    </Container>
  )
}

const Logo = styled.img`
  width: 150px;
`

const LogoContainer = styled.div`
  display: none;
  @media ${devices.tablet} {
    box-sizing: border-box;

    display: flex;
    width: 100%;
    padding: 12px;
    box-sizing: border-box;
    justify-content: center;
    align-items: center;
  }
`

const Container = styled.nav`
  background-color: ${props => props.theme.navBar};
  display: flex;

  flex: 1;
  height: 48px;
  align-items: center;
  justify-content: space-around;
  box-sizing: border-box;

  @media ${devices.tablet} {
    box-sizing: border-box;

    position: fixed;
    flex-direction: column;
    height: ${props =>
      `${props.height}px`}; // retirer la height de la barre de lecture ici
    align-items: start;
    justify-content: flex-start;
    background-color: ${props => props.theme.navBarDesktop};
    width: 210px;
  }
`

export default NavBar
