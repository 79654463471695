export default {
  translation: {
    navBar: {
      home: 'Acceuil',
      search: 'Rechercher',
      favorite: 'Favoris'
    },
    errorHandler: {
      apiError: 'Une erreur est survenue'
    },
    paypal: {
      currentCurrency: 'EUR',
      donationValue: [
        { title: 'Muslimin Play - 360H', currency: '€', value: '3.00' },
        { title: 'Muslimin Play - 600H', currency: '€', value: '5.00' },
        { title: 'Muslimin Play - 1200H', currency: '€', value: '10.00' },
        { title: 'Muslimin Play - 2400H', currency: '€', value: '20.00' },
        { title: 'Muslimin Play - 6000H', currency: '€', value: '50.00' },
        { title: "L'homme qui valait 12000H", currency: '€', value: '100.00' }
      ]
    },
    search: {
      greetings: 'Salam Alaykoum',
      instructions: 'Rechercher une sourate ou un réciteur que vous aimez.',
      search: 'Rechercher',
      reciters: 'Récitateurs trouvé',
      recentReciters: 'Récitateurs récents',
      audios: 'Pistes trouvé',
      recentAudios: 'Pistes récentes',
      noResult: 'Aucun résultat',
      noSearch: 'Rechercher dans Muslimin Play',
      noSearchSubtitle: 'Retrouvez vos reciteurs et sourates préférés.'
    },
    favorite: {
      reciters: 'Récitateur Favoris',
      audios: 'Pistes Favorites'
    },
    home: {
      lastReciters: 'Derniers Récitateurs',
      supportUs: 'Soutenez-nous',
      mekka: 'Les Récitateurs de la Mecque',
      medina: 'Les Récitateurs de medine',
      supportUsSubText:
        'Découvrez les différents moyens de soutenir Muslimin Play.',
      allReciters: 'Voir tout les récitateurs',
      allRecitersSubText: 'Parcourez toute notre sélection de récitateurs',
      welcome: 'Bienvenue sur Muslimin Play'
    },
    supportUs: {
      support: "SOUTENEZ L'EQUIPE DERRIERE MUSLIMIN PLAY",
      suportText:
        "Chaque euros donné equivaut à financer environ 120h d'écoute sur Muslimin Play, votre contribution permettra de garder le site gratuit pour tous, et de prendre en charge les frais de gestion.",
      oneTime: 'SOUTIEN PONCTUEL',
      monthly: 'SOUTIEN MENSUEL'
    },
    allArtists: {
      header: 'Tous les récitateurs',
      noMore: 'Plus de récitateurs disponible'
    },
    artist: {
      quranSearch: 'Le Saint Coran'
    },
    settings: {
      settings: 'Préférences',
      theme: 'Theme',
      language: 'Langues',
      mail: 'Signaler un problème',
      mailTo:
        "mailto:contact@muslimin-play.com?subject=Il y à un souci dans l'application",
      pageTheme: {
        darkTheme: 'Theme Sombre',
        lightTheme: 'Theme Clair'
      },
      pageLanguage: {
        french: 'Français',
        english: 'Anglais'
      }
    }
  }
}
