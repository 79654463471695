import styled from 'styled-components'
import devices from '../../config/devices'

export const MenuText = styled.span`
  color: ${props => props.theme.textColor};
  font-size: 11px;
  @media ${devices.tablet} {
    font-size: 14px;
    padding-left: 12px;
  }
`

export const DonationButtonText = styled.span`
  color: ${props => props.theme.textColor};
  font-size: 14px;
  @media ${devices.tablet} {
    font-size: 14px;
  }
`

export const SearchTextTitle = styled.span`
  color: ${props => props.theme.commonTextColor};
  font-size: 40px;
  font-weight: 600;
`

export const SearchTextSubtitle = styled.span`
  color: ${props => props.theme.commonTextColor};
  font-size: 16px;
`
export const OptionsTitle = styled.span`
  font-size: 40px;
  font-weight: bold;
  color: ${props => props.theme.commonTextColor};
`
export const OptionsRowText = styled.span`
  font-size: 16px;
  font-weight: 600;
  color: ${props => props.theme.commonTextColor};
`

export const CommonText = styled.span`
  font-size: 16px;
  color: ${props => props.theme.commonTextColor};
`

export const ArtistText = styled.span`
  font-size: 12px;
  color: ${props => props.theme.commonTextColor};
`

export const BigArtistText = styled.span`
  font-size: 20px;
  font-weight: 600;
  color: ${props => props.theme.bigArtistTextColor};
`
export const PlaylistText = styled.span`
  font-size: 20px;
  color: ${props => props.theme.playlistText};
`

export const PlaylistSubText = styled.span`
  font-size: 10px;
  color: ${props => props.theme.playlistText};
`

export const ButtonSubtext = styled.span`
  font-size: 14px;
  color: ${props => props.theme.buttonSubtext};
`

export const TrackOrderSubText = styled.span`
  font-size: 14px;
  color: ${props => props.theme.playlistText};
`

export const TitleAudioText = styled.span`
  font-size: 12px;
  font-weight: 600;
  color: ${props => props.theme.playlistText};
`

export const ArtistAudioText = styled.span`
  font-size: 12px;
  color: ${props => props.theme.playlistText};
`

export const AudioText = styled.span`
  font-size: 14px;
  font-weight: 600;
  color: ${props => props.theme.audioText};
`

export const ModalTitleText = styled.span`
  font-size: 32px;
  font-weight: 600;
  color: ${props => props.theme.playlistText};
`

export const ModalArtistText = styled.span`
  font-size: 16px;
  font-weight: 600;
  color: ${props => props.theme.artistText};
`

export const PresentationText = styled.span`
  font-size: 20px;
  font-weight: bold;
  color: ${props => props.theme.commonTextColor};
`

export const NoFavoriteText = styled.span`
  font-size: 20px;
  font-weight: bold;
  color: ${props => props.theme.commonTextColor};
`

export const ErrorText = styled.span`
  font-size: 20px;
  font-weight: bold;
  color: ${props => props.theme.commonTextColor};
`

export const FavoritePresentationText = styled.span`
  font-size: 20px;
  font-weight: bolder;
  font-style: italic;
  color: ${props => props.theme.commonTextColor};
`

export const NoSearchText = styled.span`
  font-size: 20px;
  font-weight: 600;
  color: ${props => props.theme.commonTextColor};
`

export const NoSearchSubtitleText = styled.span`
  font-size: 16px;
  color: ${props => props.theme.commonTextColor};
`

export const SupportUsText = styled.div`
  font-size: 20px;
  font-weight: bolder;
  font-style: italic;
  color: ${props => props.theme.commonTextColor};
`
