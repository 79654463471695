import React, { useContext } from 'react'
import styled, { ThemeContext } from 'styled-components'
import useWindowSize from '../../utils/useWindowSize'
import devices from '../../config/devices'
import PropTypes from 'prop-types'
import { AiOutlineDown } from 'react-icons/ai'
import {
  AudioText,
  ModalArtistText,
  ModalTitleText,
  TitleAudioText
} from '../texts'
import handleArtistsDisplay from '../../utils/handleArtistsDisplay'
import ClickableProgressionBar from '../clickableProgressionBar'

import surahDefault from '../../assets/images/surahDefault.png'

import { AiFillPlayCircle, AiOutlinePauseCircle } from 'react-icons/ai'

import { HiOutlineChevronDown } from 'react-icons/hi'
import { RiRepeatFill, RiRepeatOneFill } from 'react-icons/ri'
import { IoPlaySkipForwardSharp, IoPlaySkipBackSharp } from 'react-icons/io5'
import { FaRandom } from 'react-icons/fa'
import { useDispatch } from 'react-redux'

const SoundModal = ({
  audioRef,
  closeModal,
  currentSong,
  getNextSong,
  handleRepeat,
  getPreviousSong,
  handlePlay,
  isDisplayed,
  isPaused,
  handleIsRandom,
  isRandom,
  timeAudio,
  mode,
  progression
}) => {
  const theme = useContext(ThemeContext)
  const dispatch = useDispatch()
  const { artists, picture, title, album } = currentSong

  const { height } = useWindowSize()

  const srcImage = album?.picture?.formats?.thumbnail?.url
    ? album?.picture?.formats?.thumbnail?.url
    : surahDefault
  return (
    <ModalContainer isDisplayed={isDisplayed} height={height}>
      <Header>
        <IconTouchable onClick={closeModal}>
          <HiOutlineChevronDown size={32} color='white'></HiOutlineChevronDown>
        </IconTouchable>
        <TitleContainer>
          {/* Mettre le nom de l'album / playlist */}
          <TitleAudioText>{album?.name}</TitleAudioText>
        </TitleContainer>
      </Header>
      <AlbumContainer>
        <AlbumPicture src={srcImage}></AlbumPicture>
      </AlbumContainer>
      <InfoContainer>
        <AudioTitleContainer>
          <ModalTitleText>{title}</ModalTitleText>
        </AudioTitleContainer>
        <ArtistTitleContainer>
          <ModalArtistText>{handleArtistsDisplay(artists)}</ModalArtistText>
        </ArtistTitleContainer>
      </InfoContainer>
      <ProgressBarContainer>
        <ClickableProgressionBar
          progression={progression}
          goal={audioRef?.current?.duration}
          audioRef={audioRef}
        ></ClickableProgressionBar>
        <TimeContainer>
          <AudioText>{timeAudio.progression}</AudioText>
          <AudioText>{timeAudio.goal}</AudioText>
        </TimeContainer>
      </ProgressBarContainer>
      <Controls>
        <LeftControl>
          <IconTouchable onClick={handleIsRandom}>
            <FaRandom
              color={isRandom ? theme.controlSelected : theme.iconColor}
              size={22}
            ></FaRandom>
          </IconTouchable>
        </LeftControl>
        <CentralControl>
          <IconTouchable
            onClick={() => getPreviousSong({ userInteraction: true })}
          >
            <IoPlaySkipBackSharp
              color={theme.iconColor}
              size={32}
            ></IoPlaySkipBackSharp>
          </IconTouchable>
          <IconTouchable onClick={handlePlay}>
            {isPaused ? (
              <AiFillPlayCircle
                color={theme.iconColor}
                size={60}
              ></AiFillPlayCircle>
            ) : (
              <AiOutlinePauseCircle
                color={theme.iconColor}
                size={60}
              ></AiOutlinePauseCircle>
            )}
          </IconTouchable>
          <IconTouchable onClick={() => getNextSong({ userInteraction: true })}>
            <IoPlaySkipForwardSharp
              color={theme.iconColor}
              size={32}
            ></IoPlaySkipForwardSharp>
          </IconTouchable>
        </CentralControl>
        <RightControl>
          {mode === 'normal' || mode === 'repeatAll' ? (
            <IconTouchable onClick={() => handleRepeat(mode)}>
              <RiRepeatFill
                color={
                  mode === 'repeatAll' ? theme.controlSelected : theme.iconColor
                }
                size={22}
              ></RiRepeatFill>
            </IconTouchable>
          ) : null}
          {mode === 'repeatOne' ? (
            <IconTouchable onClick={() => handleRepeat(mode)}>
              <RiRepeatOneFill
                color={theme.controlSelected}
                size={22}
              ></RiRepeatOneFill>
            </IconTouchable>
          ) : null}
        </RightControl>
      </Controls>
    </ModalContainer>
  )
}

const TimeContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 6px;
`

const LeftControl = styled.div`
  display: flex;
`

const RightControl = styled.div`
  display: flex;
`

const CentralControl = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

const Controls = styled.div`
  padding: 12px;
  display: flex;
  justify-content: space-around;
  align-items: center;
`
const ProgressBarContainer = styled.div`
  padding: 12px;
`

const AudioTitleContainer = styled.div`
  margin-top: 12px;
`
const ArtistTitleContainer = styled.div``

const InfoContainer = styled.div`
  display: flex;
  padding: 12px;
  flex-direction: column;
`

const AlbumPicture = styled.img`
  width: 80%;
  box-shadow: 0px 0px 40px #00000030;

  ::before {
    content: ' ';
    background-image: url(surahDefault);
    width: 80%;
  }
`

const AlbumContainer = styled.div`
  margin-top: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
`

const Header = styled.div`
  width: 100%;
  display: flex;
`
const TitleContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: 56px;
`

const IconTouchable = styled.div`
  padding: 12px;
`

const ModalContainer = styled.div`
  position: fixed;
  display: ${props => (props.isDisplayed ? 'flex' : 'none')};
  flex-direction: column;
  width: 100%;
  height: ${props => `${props.height}px`};
  top: 0;
  z-index: 5;
  background-image: ${props => `linear-gradient(
    180deg,
    ${props.theme.soundModal},
    black 
  )`};
  @media ${devices.tablet} {
    display: none;
  }
`

SoundModal.propTypes = {
  audioRef: PropTypes.any,
  closeModal: PropTypes.func,
  currentSong: PropTypes.object,
  getNextSong: PropTypes.func,
  handleRepeat: PropTypes.func,
  getPreviousSong: PropTypes.func,
  handlePlay: PropTypes.func,
  isDisplayed: PropTypes.bool,
  isPaused: PropTypes.bool,
  handleIsRandom: PropTypes.func,
  isRandom: PropTypes.bool,
  timeAudio: PropTypes.object,
  mode: PropTypes.string,
  progression: PropTypes.object
}

export default SoundModal
