export const CHANGE_CURRENT_SONG = 'CHANGE_CURRENT_SONG'
export const CHANGE_CURRENT_PLAYLIST = 'CHANGE_CURRENT_PLAYLIST'
export const CHANGE_MODE = 'CHANGE_MODE'
export const SWITCH_ISRANDOM = 'SWITCH_ISRANDOM'

export const changeMode = payload => ({
  type: CHANGE_MODE,
  payload
})

export const switchIsRandom = payload => ({
  type: SWITCH_ISRANDOM,
  payload
})

export const changeCurrentSong = payload => ({
  type: CHANGE_CURRENT_SONG,
  payload
})

export const changeCurrentPlaylist = payload => ({
  type: CHANGE_CURRENT_PLAYLIST,
  payload
})
