import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import Carousel from '../../components/carousel'
import Playlist from '../../components/playlist'
import tracker from '../../utils/tracker'
import styled from 'styled-components'
import {
  FavoritePresentationText,
  NoFavoriteText,
  CommonText
} from '../../components/texts'
const Favorite = () => {
  useEffect(() => {
    tracker(window.location.pathname)
  }, [])
  const { t } = useTranslation()

  const favorite = useSelector(state => state.favorite)
  const { audios, artists } = favorite

  if (!audios[0] && !artists[0]) {
    //TODO: FAIRE UN SEUL COMPOSANT AVEC CELUI DE RECHERCHE RECENTE
    return (
      <NoSearchContainer>
        <NoFavoriteTitleContainer>
          <NoFavoriteText>Vous n'avez pas encore de favoris</NoFavoriteText>
        </NoFavoriteTitleContainer>
        <CommonText>
          Mettez un réciteur ou une piste en favoris en appuyant sur le ♡ dans
          le lecteur ou sur la page du réciteur
        </CommonText>
      </NoSearchContainer>
    )
  }
  return (
    <Container>
      <TitleContainer>
        <FavoritePresentationText>
          {' '}
          VOS COUPS DE COEURS
        </FavoritePresentationText>
      </TitleContainer>
      <Carousel
        type='artist'
        label={t('favorite.reciters')}
        loading={false}
        data={artists}
      ></Carousel>
      <PlaylistContainer>
        <Playlist
          displayArtist={true}
          label={t('favorite.audios')}
          audios={audios}
          loading={false}
        ></Playlist>
      </PlaylistContainer>
    </Container>
  )
}

const PlaylistContainer = styled.div`
  padding: 6px;
`

const TitleContainer = styled.div`
  margin-top: 34px;
  margin-bottom: 12px;
  padding: 12px;
`

const NoFavoriteTitleContainer = styled.div`
  margin-bottom: 12px;
`

const NoSearchContainer = styled.div`
  display: flex;
  width: 100%;
  padding: 0px 12px;
  flex-direction: column;
  height: 80vh;
  text-align: center;
  justify-content: center;
  align-items: center;
  background-image: ${props => `linear-gradient(
    200deg,
    ${props.theme.backgroundSecondary} 20px,
    transparent 220px
  )`};
`

const Container = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  background-image: ${props => `linear-gradient(
    200deg,
    ${props.theme.backgroundSecondary} 20px,
    transparent 220px
  )`};
`

export default Favorite
