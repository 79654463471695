export const lightTheme = {
  artistPrimary: '#1ed760',
  artistSecondary: 'transparent',
  artistText: '#c5c5c5',
  audioText: '#c5c5c5',
  backBar: '#0e0e0eF6',
  backgroundPrimary: '#3164f4a0',
  backgroundSecondary: '#5e24d4a0',
  bigArtistTextColor: '#ffffff',
  buttonSubtext: '#C2C2C2',
  commonTextColor: '#ffffff',
  controlSelected: '#1ed760',
  explanation: '#9e76eb',
  explanationSecondary: '#7a9bd5',
  iconColor: '#ffffff',
  iconColorSecondary: '#ffffff',
  linearPrimary: '#5e24d4a0',
  linearSecondary: '#3164f4a0',
  navBar: 'rgb(40, 40, 40)',
  navBarDesktop: '#000000',
  patreon: '#fc674f',
  paypal: '#449cdc',
  playlistRow: '#ffffff10',
  playlistText: '#ffffff',
  primary: '#000000',
  progressBarContainer: '#c5c5c530',
  searchBar: 'rgb(83, 83, 83)',
  searchBarIcon: '#ffffff',
  searchBarText: '#ffffff',
  secondary: '#ffffff',
  soundModal: '#7a5290',
  supportUsButton: 'rgb(40, 40, 40)',
  textColor: '#ffffff'
}

export const darkTheme = {
  primary: '#222222',
  iconColor: '#ffffff',
  iconColorSecondary: '#ffffff',
  artistPrimary: '#222222',
  artistSecondary: 'transparent',
  navBar: '#1ed760',
  searchBar: '#1ed760',
  searchBarIcon: '#ffffff',
  searchBarText: '#ffffff',
  textColor: '#ffffff',
  CommonTextColor: '#ffffff'
}
