import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import styled, { ThemeContext } from 'styled-components'
import { ErrorText } from '../texts'
import { IoRefreshCircleOutline } from 'react-icons/io5'

const ErrorMessage = ({ message, onClick, networkStatus }) => {
  //TODO:TESTER QUAND PWA
  const theme = useContext(ThemeContext)
  if (networkStatus.refetching) {
    return (
      <MessageContainer>
        <ErrorText>Refetching</ErrorText>
      </MessageContainer>
    )
  }

  return (
    <MessageContainer>
      <ErrorText>{message}</ErrorText>
      <IconContainer onClick={onClick}>
        <IoRefreshCircleOutline
          color={theme.iconColor}
          size={34}
        ></IoRefreshCircleOutline>
      </IconContainer>
    </MessageContainer>
  )
}

const IconContainer = styled.div`
  cursor: pointer;
  padding: 12px;
`

const MessageContainer = styled.div`
  width: 100%;
  padding: 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
`

ErrorMessage.propTypes = {}

export default ErrorMessage
