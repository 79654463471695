import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { motion } from 'framer-motion'

const ClickableProgressionBar = ({
  goal,
  progression,
  heigth = 8,
  audioRef
}) => {
  // console.log('🚀 ~ file: index.js ~ line 13 ~ timeAudio', timeAudio)
  const [progress, setProgress] = useState(0)
  const progressBarRef = useRef(null)
  useEffect(() => {
    const ratio = (progression / goal) * 100
    setProgress(ratio)
  }, [progression])

  const handleClick = e => {
    const correctedClientX = event.offsetX
      ? event.offsetX
      : event.pageX - progressBarRef.offsetLeft

    // const correctedClientX = e.clientX - 8
    // console.log(
    //   '🚀 ~ file: index.js ~ line 22 ~ correctedClientX',
    //   correctedClientX
    // )
    const duration = audioRef.current.duration
    const pct = Math.floor(
      (correctedClientX / progressBarRef.current.offsetWidth) * 100
    )
    audioRef.current.currentTime = duration * (pct / 100)
    setProgress(pct)
  }

  return (
    <ProgressionBarContainer
      heigth={heigth}
      ref={ref => (progressBarRef.current = ref)}
      onClick={handleClick}
    >
      <Bar heigth={heigth} progress={progress}></Bar>
    </ProgressionBarContainer>
  )
}

const Bar = styled.div.attrs(props => ({
  style: {
    width: props.progress ? `${props.progress}%` : '0%'
  }
}))`
  height: ${props => `${props.heigth}px`};
  background-color: white;
`

const ProgressionBarContainer = styled.div`
  background-color: ${props => props.theme.progressBarContainer};
  width: 100%;
  height: ${props => `${props.heigth}px`};
`

ClickableProgressionBar.propTypes = {
  goal: PropTypes.number,
  progression: PropTypes.number,
  heigth: PropTypes.number,
  audioRef: PropTypes.any
}

export default ClickableProgressionBar
