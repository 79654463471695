import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { PresentationText } from '../texts'
import PlaylistRow from '../playlistRow'
import { useDispatch, useSelector } from 'react-redux'
import allTheActions from '../../actions'
import _ from 'lodash'

const Playlist = ({
  audios = [],
  label,
  picture = {},
  displayArtist,
  loading,
  isSearch
}) => {
  const dispatch = useDispatch()
  const currentPlaylist = useSelector(
    state => state.audioPlayer.currentPlaylist
  )

  const handleClick = audio => {
    // Change The Current SOng
    const { url, title, artists, id, album } = audio
    dispatch(
      allTheActions.audioPlayer.changeCurrentSong({
        ...audio
      })
    )
    if (isSearch) {
      dispatch(allTheActions.recent.addToRecentAudios({ ...audio }))
      dispatch(
        allTheActions.audioPlayer.changeCurrentPlaylist({
          artists: artists,
          title: label,
          audios: [audio],
          picture: album?.picture?.formats?.thumbnail?.url
        })
      )
      return
    }

    // Check if the Artist/Album name is not the Same and change the playlist if it is not,
    if (
      !_.isEqual(artists, currentPlaylist.artists) &&
      title !== currentPlaylist.title
    ) {
      dispatch(
        allTheActions.audioPlayer.changeCurrentPlaylist({
          artists: artists,
          title: label,
          audios: audios,
          picture: picture
        })
      )
    }
  }

  return (
    <PlaylistContainer>
      {loading ? <p>loading</p> : null}
      {audios[0] ? <PresentationText>{label}</PresentationText> : null}
      {audios.map(audio => {
        return (
          <PlaylistRow
            handleClick={() => handleClick(audio)}
            artists={audio.artists}
            key={audio.id}
            title={audio.title}
            url={audio.url}
            titlePhonetic={audio.titlePhonetic}
            trackOrder={audio.trackOrder}
            displayArtist={displayArtist}
          ></PlaylistRow>
        )
      })}
    </PlaylistContainer>
  )
}

const PlaylistContainer = styled.div`
  padding: 6px;
  padding-bottom: 124px;
  padding-left: 12px;
  padding-right: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0px 12px;
`

Playlist.propTypes = {
  audios: PropTypes.string,
  label: PropTypes.string,
  picture: PropTypes.string,
  displayArtist: PropTypes.func,
  loading: PropTypes.bool,
  isSearch: PropTypes.func
}

export default Playlist
