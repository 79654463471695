import React, { useContext, useEffect } from 'react'
import styled, { ThemeContext } from 'styled-components'
import SettingsBar from '../../components/settingsBar'
import tracker from '../../utils/tracker'
import { useQuery } from '@apollo/client'

import { artists } from '../../graphql/queries'
import getLocale from '../../utils/getLocale'
import Carousel from '../../components/carousel'
import { useTranslation } from 'react-i18next'
import { AiOutlineDollarCircle, AiOutlinePlus } from 'react-icons/ai'
import ButtonDescription from '../../components/buttonDescription'
import ErrorMessage from '../../components/errorMessage'
import donateImage from '../../assets/images/donateImage.png'
import artistDefault from '../../assets/images/artistDefault.png'

const Home = () => {
  const { t } = useTranslation()
  const theme = useContext(ThemeContext)
  const { loading, error, data, refetch, networkStatus } = useQuery(
    artists.GET_HOME_PAGE,
    {
      variables: { locale: getLocale(), limit: 10 }
    }
  )

  useEffect(() => {
    tracker(window.location.pathname)
  }, [])
  return (
    <Container>
      <SettingsBar></SettingsBar>
      <ButtonDescription
        icon={AiOutlineDollarCircle}
        label={t('home.supportUs')}
        subText={t('home.supportUsSubText')}
        imgSrc={donateImage}
        link='supportUs'
      ></ButtonDescription>
      {error ? (
        <ErrorMessage
          networkStatus={networkStatus}
          onClick={() => refetch()}
          message={t('errorHandler.apiError')}
        >
          {error}
        </ErrorMessage>
      ) : null}

      <Carousel
        type='artist'
        label={t('home.lastReciters')}
        loading={loading}
        data={data?.artists}
      ></Carousel>
      <Carousel
        type='artist'
        label={t('home.medina')}
        loading={loading}
        data={data?.medina?.artists}
      ></Carousel>
      <Carousel
        type='artist'
        label={t('home.mekka')}
        loading={loading}
        data={data?.mekka?.artists}
      ></Carousel>
      <ButtonDescription
        icon={AiOutlinePlus}
        label={t('home.allReciters')}
        subText={t('home.allRecitersSubText')}
        imgSrc={artistDefault}
        link='/allArtists'
      ></ButtonDescription>
    </Container>
  )
}

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  background-image: ${props => `linear-gradient(
    150deg,
    ${props.theme.backgroundPrimary} 20px,
    transparent 220px
  )`};
`

export default Home
