import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import devices from '../../config/devices'
import { DonationButtonText, MenuText } from '../texts'

const DonationButton = ({
  icon: Icon,
  label,
  iconColor,
  iconSize = 14,
  onClick,
  buttonColor,
  isPending
}) => {
  return (
    <ButtonContainer
      backgroundColor={isPending ? 'grey' : buttonColor}
      onClick={isPending ? () => 'null' : onClick}
    >
      <IconContainer>
        <Icon color={iconColor} size={iconSize}></Icon>
      </IconContainer>
      <DonationButtonText>{label}</DonationButtonText>
    </ButtonContainer>
  )
}

const IconContainer = styled.div`
  margin-right: 12px;
`

const ButtonContainer = styled.div`
  cursor: pointer;
  background-color: ${props => props.backgroundColor};
  display: flex;
  padding: 12px;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  border-radius: 4px;
  @media ${devices.tablet} {
  }
`

DonationButton.propTypes = {
  icon: PropTypes.any,
  label: PropTypes.string,
  iconColor: PropTypes.string,
  buttonColor: PropTypes.string,
  iconSize: PropTypes.number,
  onClick: PropTypes.func,
  isPending: PropTypes.bool
}

export default DonationButton
