import React from 'react'
import { Router, Switch, Route, Redirect } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { ThemeProvider } from 'styled-components'

import Home from '../screens/home'
import Search from '../screens/search'
import Favorite from '../screens/favorite'
import Recent from '../screens/recent'
import Layout from '../components/layout'
import Settings from '../screens/settings'
import Themes from '../screens/themes'
import Language from '../screens/language'
import Artist from '../screens/artist'
import AllArtists from '../screens/allArtists'
import SupportUs from '../screens/supportUs'

import GlobalStyle from '../config/globalStyle'

import history from '../utils/history'
import { lightTheme } from '../config/themes'

const Routes = () => {
  const currentTheme = useSelector(state => state.themes.currentTheme)
  return (
    <ThemeProvider theme={lightTheme}>
      <GlobalStyle></GlobalStyle>

      <Router history={history}>
        <Layout>
          <Switch>
            <Route exact path='/' component={Home}></Route>
            <Route exact path='/settings' component={Settings}></Route>
            <Route path='/supportUs' component={SupportUs}></Route>
            <Route exact path='/artist/:id' component={Artist}></Route>
            <Route path='/settings/themes' component={Themes}></Route>
            <Route path='/settings/language' component={Language}></Route>
            <Route exact path='/search' component={Search}></Route>
            <Route exact path='/search/recent' component={Recent}></Route>
            <Route path='/favorite' component={Favorite}></Route>
            <Route path='/allArtists' component={AllArtists}></Route>
            <Redirect to='/'></Redirect>
          </Switch>
        </Layout>
      </Router>
    </ThemeProvider>
  )
}

export default Routes
