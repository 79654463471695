import React, { useContext } from 'react'
// import PropTypes from 'prop-types'
import styled, { ThemeContext } from 'styled-components'
import { AiOutlineSetting } from 'react-icons/ai'
import handleNavigation from '../../utils/handleNavigation'
import { CommonText, PresentationText } from '../texts'
import { useTranslation } from 'react-i18next'

const SettingsBar = () => {
  const theme = useContext(ThemeContext)
  const { t } = useTranslation()
  return (
    <Container>
      <TextContainer>
        <PresentationText>{t('home.welcome')}</PresentationText>
      </TextContainer>
      <SettingsContainer onClick={() => handleNavigation('settings')}>
        <AiOutlineSetting
          color={theme.iconColorSecondary}
          size={24}
        ></AiOutlineSetting>
      </SettingsContainer>
    </Container>
  )
}

const TextContainer = styled.div`
  padding-left: 12px;
`

const SettingsContainer = styled.div`
  padding: 24px;
`
const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 12px;
`

SettingsBar.propTypes = {}

export default SettingsBar
