import React, { useRef, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import FlashMessage from 'react-flash-message'
import _ from 'lodash'

import AudioPlayer from '../audioPlayer'
import allTheActions from '../../actions'
import NavBar from '../navBar'
import devices from '../../config/devices'
import SoundModal from '../soundModal'
import leftPad from 'just-left-pad'
import useWindowSize from '../../utils/useWindowSize'
import AudioPlayerDesktop from '../audioPlayerDesktop'

const Layout = props => {
  const dispatch = useDispatch()
  const [progression, setProgression] = useState(0)
  const checkProgression = useRef(null)
  const windowSize = useWindowSize()

  const updateTimer = useRef(null)
  const [isPaused, setIsPaused] = useState(true)
  const [timeAudio, setTimeAudio] = useState({
    progression: '00:00',
    goal: '00:00'
  })
  const audioRef = useRef(null)
  const audioPlayer = useSelector(state => state.audioPlayer)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const { currentPlaylist, currentSong, mode, isRandom } = audioPlayer
  const { audios } = currentPlaylist

  const secondsToHms = (d, f) => {
    const h = Math.floor(d / 3600)
    const m = Math.floor((d % 3600) / 60)
    const s = Math.floor((d % 3600) % 60)
    const hf = Math.floor(f / 3600)
    const mf = Math.floor((f % 3600) / 60)
    const sf = Math.floor((f % 3600) % 60)

    const stringProgression = `${
      h > 0 ? `${leftPad(`${h}`, 2, '0')}:` : ''
    }${leftPad(`${m}`, 2, '0')}:${leftPad(`${s}`, 2, '0')}`

    const stringGoal = `${
      hf > 0 ? `${leftPad(`${hf}`, 2, '0')}:` : ''
    }${leftPad(`${mf}`, 2, '0')}:${leftPad(`${sf}`, 2, '0')}`

    setTimeAudio({
      ...timeAudio,
      progression: stringProgression,
      goal: stringGoal
    })
  }

  useEffect(() => {
    checkProgression.current = setInterval(() => {
      setProgression(audioRef.current.currentTime)
    }, 200)

    updateTimer.current = setInterval(() => {
      secondsToHms(audioRef.current.currentTime, audioRef.current.duration)
    }, 1000)
    return () => {
      clearInterval(checkProgression.current)
      clearInterval(updateTimer.current)
    }
  }, [])

  useEffect(() => {
    // // evite de lancer le son au rechargement de la page
    // window.onbeforeunload = () => {
    //   return
    // }
    audioRef.current.play()
    setIsPaused(false)
  }, [currentSong.id])

  const getNextSong = async ({ userInteraction = false }) => {
    if (!userInteraction) {
      if (mode === 'repeatOne') {
        audioRef.current.currentTime = 0
        audioRef.current.play()
        return
      }
    } else {
      if (mode === 'repeatOne') {
        dispatch(allTheActions.audioPlayer.changeMode('repeatAll'))
      }
    }

    const indexAudio = await getCurrentAudioIndex()

    if (audios[indexAudio + 1]) {
      await dispatch(
        allTheActions.audioPlayer.changeCurrentSong({
          ...audios[indexAudio + 1]
        })
      )
      setIsPaused(false)
      audioRef.current.play()
    } else {
      if (mode === 'repeatAll') {
        await dispatch(
          allTheActions.audioPlayer.changeCurrentSong({
            ...audios[0]
          })
        )
        audioRef.current.currentTime = 0
        audioRef.current.play()

        return
      }

      audioRef.current.pause()
      setIsPaused(true)
      audioRef.current.currentTime = 0
    }
  }
  const handleRepeat = mode => {
    switch (mode) {
      case 'normal':
        return dispatch(allTheActions.audioPlayer.changeMode('repeatAll'))
      case 'repeatAll':
        return dispatch(allTheActions.audioPlayer.changeMode('repeatOne'))
      case 'repeatOne':
        return dispatch(allTheActions.audioPlayer.changeMode('normal'))
      default:
    }
  }

  const handlePlay = () => {
    const { paused, duration } = audioRef.current

    if (paused) {
      audioRef.current.play()
      setIsPaused(false)
    } else {
      audioRef.current.pause()
      setIsPaused(true)
    }
  }

  const getCurrentAudioIndex = async () => {
    const indexAudio = await _.findIndex(
      audios,
      audio => audio.id === currentSong.id
    )
    return indexAudio
  }

  const handleModal = openModal => {
    setIsModalOpen(openModal)
  }
  const handleIsRandom = () => {
    dispatch(allTheActions.audioPlayer.switchIsRandom())
  }

  const getPreviousSong = async (userInteraction = false) => {
    if (!userInteraction) {
      if (mode === 'repeatOne') {
        audioRef.current.currentTime = 0
        audioRef.current.play()
        return
      }
    } else {
      if (audioRef.current.currentTime >= 3) {
        audioRef.current.currentTime = 0
        audioRef.current.play()
        return
      } else {
        if (mode === 'repeateOne') {
          dispatch(allTheActions.audioPlayer.changeMode('repeatAll'))
        }
      }
    }

    const indexAudio = await getCurrentAudioIndex()

    if (audios[indexAudio - 1]) {
      await dispatch(
        allTheActions.audioPlayer.changeCurrentSong({
          ...audios[indexAudio - 1]
        })
      )
      // setIsPaused(false)
      if (!isPaused) {
        audioRef.current.play()
      }
    } else {
      if (mode === 'repeatAll') {
        await dispatch(
          allTheActions.audioPlayer.changeCurrentSong({
            ...audios[audios.length - 1]
          })
        )
        if (!isPaused) {
          audioRef.current.play()
        }

        return
      }
      audioRef.current.currentTime = 0
    }
  }

  return (
    <AllWrapper>
      <AllContainer height={windowSize.height}>
        <ContentContainer>{props.children}</ContentContainer>
        <SoundModal
          audioRef={audioRef}
          closeModal={() => handleModal(false)}
          currentSong={currentSong}
          getNextSong={getNextSong}
          getPreviousSong={getPreviousSong}
          handlePlay={handlePlay}
          isDisplayed={isModalOpen}
          handleRepeat={handleRepeat}
          isPaused={isPaused}
          isRandom={isRandom}
          handleIsRandom={handleIsRandom}
          mode={mode}
          timeAudio={timeAudio}
          progression={progression}
          setProgression={setProgression}
        ></SoundModal>

        <NavBarHandler>
          <AudioHandler>
            <AudioHandler>
              <audio
                ref={ref => (audioRef.current = ref)}
                onEnded={getNextSong}
                src={currentSong?.url}
              ></audio>

              <AudioPlayer
                openModal={() => handleModal(true)}
                isModalOpen={isModalOpen}
                currentSong={currentSong}
                audioRef={audioRef}
                progression={progression}
                setProgression={setProgression}
                handlePlay={handlePlay}
                isPaused={isPaused}
              ></AudioPlayer>
            </AudioHandler>
          </AudioHandler>
          <NavBar></NavBar>
        </NavBarHandler>
      </AllContainer>
      <AudioPlayerDesktopContainer>
        <AudioPlayerDesktop
          openModal={() => handleModal(true)}
          isModalOpen={isModalOpen}
          getNextSong={getNextSong}
          currentSong={currentSong}
          isRandom={isRandom}
          timeAudio={timeAudio}
          getPreviousSong={getPreviousSong}
          handleIsRandom={handleIsRandom}
          audioRef={audioRef}
          progression={progression}
          setProgression={setProgression}
          mode={mode}
          handlePlay={handlePlay}
          isPaused={isPaused}
          handleRepeat={handleRepeat}
        ></AudioPlayerDesktop>
      </AudioPlayerDesktopContainer>
    </AllWrapper>
  )
}

const MessageDisplayer = styled.div`
  position: absolute;
  width: 100%;
  background-color: red;
`

const AudioPlayerDesktopContainer = styled.div`
  position: fixed;
  bottom: 0;
  z-index: 4;
  width: 100%;
`

const AllWrapper = styled.div``

const AudioHandler = styled.div``

const ContentContainer = styled.div`
  width: 100%;
  display: flex;
  flex-grow: 1;
  box-sizing: border-box;
  padding-bottom: 128px;

  @media ${devices.tablet} {
    /* padding-left: 24px; */
  }
`

const NavBarHandler = styled.div`
  position: fixed;
  bottom: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  @media ${devices.tablet} {
    position: relative;
    max-width: 241px;
    bottom: none;
  }
`

const AllContainer = styled.div`
  display: flex;
  width: 100%;
  @media ${devices.tablet} {
    flex-direction: row-reverse;
    min-height: ${props => `${props.height}px`};
  }
`

Layout.propTypes = {
  children: PropTypes.node
}

export default Layout
