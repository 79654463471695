const color = [
  '#3164f491',
  '#5d25d491',
  '#6c337691',
  '#1b1a4d91',
  '#150e1f91',
  '#3c266491',
  '#7a529091',
  '#30637091',
  '#304d7091',
  '#70543091',
  '#70393091',
  '#70303091'
]

const getRandomInt = max => {
  return Math.floor(Math.random() * max)
}
const getRandomColor = () => {
  const colorIndex = getRandomInt(color.length)

  return color[colorIndex]
}

export default getRandomColor
