import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import handleNavigation from '../../utils/handleNavigation'
import { ThemeContext } from 'styled-components'
import { ButtonSubtext, CommonText } from '../texts'
import styled from 'styled-components'

const ButtonDescription = ({ label, subText, icon: Icon, imgSrc, link }) => {
  const theme = useContext(ThemeContext)
  return (
    <ButtonDescriptionContainer>
      <SupportUsButton onClick={() => handleNavigation(link)}>
        <ImageContainer>
          {imgSrc ? <ImageButton src={imgSrc}></ImageButton> : null}
        </ImageContainer>
        <TextContainer>
          <LabelContainer>
            <CommonText>{label}</CommonText>
          </LabelContainer>
          <SubTextContainer>
            <ButtonSubtext>{subText}</ButtonSubtext>
          </SubTextContainer>
        </TextContainer>
        <IconContainer>
          <Icon size={32} color={theme.commonTextColor}></Icon>
        </IconContainer>
      </SupportUsButton>
    </ButtonDescriptionContainer>
  )
}

const LabelContainer = styled.div``
const SubTextContainer = styled.div``
const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: 12px;
`

const ImageButton = styled.img`
  width: 60px;
  height: 60px;
  border-radius: 2px;
`

const TextContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`

const IconContainer = styled.div`
  justify-content: center;
  display: flex;
  align-items: center;
`

const SupportUsButton = styled.div`
  padding: 16px;
  display: flex;
  background-color: ${props => props.theme.supportUsButton};
  border-radius: 8px;
  cursor: pointer;
  max-width: 400px;
`

const ButtonDescriptionContainer = styled.div`
  padding: 12px;
`

ButtonDescription.propTypes = {}

export default ButtonDescription
