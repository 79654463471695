import { ADD_TO_RECENT_ARTIST, ADD_TO_RECENT_AUDIOS } from '../actions/recent'

const initialState = {
  artists: [],
  audios: []
}

const addToRecentArtist = (state, payload) => {
  const artistsFiltered = state.artists.filter(
    artist => artist.id !== payload.id
  )
  artistsFiltered.unshift(payload)
  return artistsFiltered.slice(0, 20)
}

const addToRecentAudios = (state, payload) => {
  const audiosFiltered = state.audios.filter(audios => audios.id !== payload.id)
  audiosFiltered.unshift(payload)
  return audiosFiltered.slice(0, 20)
}

export default (state = initialState, action) => {
  switch (action.type) {
    case ADD_TO_RECENT_ARTIST:
      return {
        ...state,
        artists: addToRecentArtist(state, action.payload)
      }
    case ADD_TO_RECENT_AUDIOS:
      return {
        ...state,
        audios: addToRecentAudios(state, action.payload)
      }
    default:
      return state
  }
}
