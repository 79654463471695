import React, { useContext, useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import styled, { ThemeContext } from 'styled-components'
import { AiOutlineHeart, AiFillHeart } from 'react-icons/ai'
import { BsFillPauseFill, BsFillPlayFill } from 'react-icons/bs'
import { ArtistAudioText, TitleAudioText } from '../texts'
import ProgressionBar from '../progressionBar'
import handleArtistsDisplay from '../../utils/handleArtistsDisplay'

import addOrRemoveAudioToFavorite from '../../utils/addOrRemoveAudioToFavorite'
import { useDispatch, useSelector } from 'react-redux'
import devices from '../../config/devices'

const AudioPlayer = ({
  audioRef,
  currentSong,
  openModal,
  handlePlay,
  isPaused
}) => {
  if (!audioRef.current || !currentSong.id) return null
  const dispatch = useDispatch()
  const theme = useContext(ThemeContext)
  const [isFavorite, setIsFavorite] = useState(false)
  const favoriteAudios = useSelector(state => state.favorite.audios)

  useEffect(() => {
    const checkFavorite = favoriteAudios.filter(
      fav => fav.id === currentSong.id
    )
    if (checkFavorite[0]) {
      setIsFavorite(true)
    } else {
      setIsFavorite(false)
    }
  }, [currentSong])

  return (
    <Container>
      <ProgressionBar
        progression={audioRef.current.currentTime}
        goal={audioRef.current.duration}
      ></ProgressionBar>
      <AudioPlayerContainer>
        <IconTouchable
          onClick={() =>
            addOrRemoveAudioToFavorite({
              data: currentSong,
              isFavorite,
              setIsFavorite,
              dispatch,
              type: 'audios'
            })
          }
        >
          {isFavorite ? (
            <AiFillHeart color={theme.iconColor} size={24}></AiFillHeart>
          ) : (
            <AiOutlineHeart color={theme.iconColor} size={24}></AiOutlineHeart>
          )}
        </IconTouchable>
        <TextContainer onClick={openModal}>
          <TitleContainer>
            <TitleAudioText>{currentSong.title}</TitleAudioText>
          </TitleContainer>
          <ArtistContainer>
            <ArtistAudioText>
              {handleArtistsDisplay(currentSong.artists)}
            </ArtistAudioText>
          </ArtistContainer>
        </TextContainer>
        <IconTouchable onClick={handlePlay}>
          {isPaused ? (
            <BsFillPlayFill color={theme.iconColor} size={24}></BsFillPlayFill>
          ) : (
            <BsFillPauseFill
              color={theme.iconColor}
              size={24}
            ></BsFillPauseFill>
          )}
        </IconTouchable>
      </AudioPlayerContainer>
    </Container>
  )
}

const Container = styled.div`
  @media ${devices.tablet} {
    display: none;
  }
`

const TitleContainer = styled.div``
const ArtistContainer = styled.div``

const TextContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  padding-top: 4px;
  flex-direction: column;
  padding-bottom: 4px;
`

const IconTouchable = styled.div`
  padding: 3px 12px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
`

const AudioPlayerContainer = styled.div`
  display: flex;
  background-color: ${props => props.theme.navBar};
  border-bottom: 1px solid ${props => props.theme.secondary};
`

AudioPlayer.propTypes = {
  audioRef: PropTypes.any,
  currentSong: PropTypes.object,
  openModal: PropTypes.func,
  handlePlay: PropTypes.func,
  isPaused: PropTypes.bool
}

export default AudioPlayer
