const size = {
  mobileL: '425px',
  tablet: '769px',
  laptop: '1024px'
}

const devices = {
  mobileL: `(min-width: ${size.mobileL})`,
  tablet: `(min-width: ${size.tablet})`,
  laptop: `(min-width: ${size.laptop})`
}

export default devices
