import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import { changeTheme } from '../../actions/themes'
import HeaderBack from '../../components/headerBack'
import OptionsHeader from '../../components/optionsHeader'
import OptionsRow from '../../components/optionsRow'

import { lightTheme, darkTheme } from '../../config/themes'
import tracker from '../../utils/tracker'

const Theme = () => {
  const [t] = useTranslation()
  const dispatch = useDispatch()
  const handleChangeTheme = theme => {
    dispatch(changeTheme(theme))
  }
  useEffect(() => {
    tracker(window.location.pathname)
  }, [])
  return (
    <Container>
      <HeaderBack></HeaderBack>
      <OptionsHeader label={t('settings.theme')}></OptionsHeader>
      <OptionsRow
        onClick={() => handleChangeTheme(darkTheme)}
        label={t('settings.pageTheme.darkTheme')}
      ></OptionsRow>
      {/* <OptionsRow
        onClick={() => handleChangeTheme(lightTheme)}
        label={t('settings.pageTheme.lightTheme')}
      ></OptionsRow> */}
    </Container>
  )
}

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  background-image: ${props => `linear-gradient(
      200deg,
      ${props.theme.linearPrimary},
      rgba(255, 0, 0, 0) 60.71%
    ),
    linear-gradient(160deg, ${props.theme.linearSecondary}, rgba(0, 255, 0, 0) 60.71%),
    linear-gradient(0deg, rgba(0, 0, 0, 1), rgba(01, 20, 20, 1) 100%)`};
`

export default Theme
