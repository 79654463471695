import { gql } from '@apollo/client'

export const GET_AUDIOS = gql`
  query Audios($locale: String) {
    audios(locale: $locale) {
      id
      title
    }
  }
`

export const GET_AUDIOS_FROM_ALBUM = gql`
  query Audios($locale: String, $name: String, $limit: Int, $id: String) {
    audios(
      locale: $locale
      limit: $limit
      where: { album: { name: $name }, artists: { id: $id } }
      sort: "trackOrder:ASC"
    ) {
      id
      title
      titlePhonetic
      url
    }
  }
`
