import React from 'react'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import Carousel from '../carousel'
import Playlist from '../playlist'
import { useTranslation } from 'react-i18next'
import { NoSearchText, NoSearchSubtitleText } from '../texts'

import useWindowSize from '../../utils/useWindowSize'

const RecentSearch = () => {
  const { t } = useTranslation()
  const recent = useSelector(state => state.recent)
  const { height } = useWindowSize()

  const { audios, artists } = recent
  if (!audios[0] && !artists[0]) {
    return (
      <NoSearchContainer height={height}>
        <NoSearchText>{t('search.noSearch')}</NoSearchText>
        <SubtitleTextContainer>
          <NoSearchSubtitleText>
            {t('search.noSearchSubtitle')}
          </NoSearchSubtitleText>
        </SubtitleTextContainer>
      </NoSearchContainer>
    )
  }
  return (
    <Container>
      <Carousel
        type='artist'
        label={t('search.recentReciters')}
        loading={false}
        data={artists}
        isSearch={true}
      ></Carousel>
      <Playlist
        isSearch={true}
        displayArtist={true}
        label={t('search.recentAudios')}
        audios={audios}
        loading={false}
      ></Playlist>
    </Container>
  )
}

const SubtitleTextContainer = styled.div`
  margin-top: 12px;
`

const NoSearchContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  text-align: center;
  padding-top: ${props => `${props.height / 3}px`};
  justify-content: center;
  align-items: center;
`

const Container = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`

RecentSearch.propTypes = {}

export default RecentSearch
