import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { CommonText } from '../texts'
import artistDefault from '../../assets/images/artistDefault.png'
import { useHistory } from 'react-router'

const ArtistRow = ({ artist }) => {
  const history = useHistory()
  const srcImage = artist?.picture?.formats?.thumbnail.url
    ? artist?.picture?.formats?.thumbnail.url
    : artistDefault
  return (
    <Container onClick={() => history.push(`/artist/${artist.id}`)}>
      <Avatar src={srcImage}></Avatar>
      <TextContainer>
        <CommonText>{artist?.name}</CommonText>
      </TextContainer>
    </Container>
  )
}

const TextContainer = styled.div`
  margin-left: 12px;
`

const Container = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 12px;
  border-radius: 4px;
  cursor: pointer;
`

const Avatar = styled.img`
  width: 70px;
  height: 70px;
  object-fit: cover;
  border-radius: 4px;
`

ArtistRow.propTypes = {
  artist: PropTypes.object.isRequired
}

export default ArtistRow
