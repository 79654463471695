import React, { useContext, useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import styled, { ThemeContext } from 'styled-components'
import HeaderBack from '../../components/headerBack'

import { useTranslation } from 'react-i18next'
import SupportUsImg from '../../assets/images/supportUs.png'
import { CommonText, SupportUsText } from '../../components/texts'
import { DonationButton } from '../../components/buttons'
import { FaPatreon } from 'react-icons/fa'
import { usePayPalScriptReducer } from '@paypal/react-paypal-js'
import PaypalButton from '../../components/buttons/paypalButton'
import devices from '../../config/devices'
import tracker from '../../utils/tracker'

const SupportUs = props => {
  const imageRef = useRef(null)
  const theme = useContext(ThemeContext)
  const [loaded, setLoaded] = useState(false)
  const { t } = useTranslation()
  const paypalValues = t('paypal', { returnObjects: true })

  useEffect(() => {
    tracker(window.location.pathname)
  }, [])

  const openNewTab = url => {
    window.open(url, '_blank').focus()
  }

  return (
    <Container>
      <SupportUsImage
        onLoad={() => setLoaded(true)}
        ref={ref => {
          imageRef.current = ref
        }}
        src={SupportUsImg}
      ></SupportUsImage>
      <HeaderBack></HeaderBack>
      <TextContainer>
        <SupportUsText>{t('supportUs.support')}</SupportUsText>
      </TextContainer>
      {imageRef.current?.height ? (
        <ContentContainer marginTop={imageRef.current?.height}>
          <ExplanationContainer>
            <CommonText>{t('supportUs.suportText')}</CommonText>
          </ExplanationContainer>
          <TitleContainer>
            <SupportUsText>{t('supportUs.monthly')}</SupportUsText>
          </TitleContainer>
          <SupportButtonContainer>
            <DonationButton
              onClick={() => openNewTab('https://www.patreon.com/thekfcompany')}
              icon={FaPatreon}
              iconColor={theme.iconColor}
              label='PATREON'
              buttonColor={theme.patreon}
            ></DonationButton>
          </SupportButtonContainer>
          <TitleContainer>
            <SupportUsText>{t('supportUs.oneTime')}</SupportUsText>
          </TitleContainer>
          <SupportButtonContainer>
            {paypalValues.donationValue.map(item => (
              <PaypalButtonContainer key={item.value}>
                <PaypalButton
                  item={item}
                  currency={paypalValues.currentCurrency}
                />
              </PaypalButtonContainer>
            ))}
          </SupportButtonContainer>
        </ContentContainer>
      ) : null}
    </Container>
  )
}

const PaypalButtonContainer = styled.div`
  margin: 6px 12px;
  @media ${devices.tablet} {
    margin: 6px 12px;
  }
`

const ExplanationContainer = styled.div`
  border-radius: 4px;
  width: 100%;
  box-sizing: border-box;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px;
  margin-bottom: 12px;
  background-image: ${props => `linear-gradient(
      90deg,
      ${props.theme.explanation},
      rgba(255, 0, 0, 0)
    ),
    linear-gradient(200deg, ${props.theme.explanationSecondary}, rgba(0, 255, 0, 0) );`};
  @media ${devices.tablet} {
    max-width: 720px;
  }
`

const SupportButtonContainer = styled.div`
  width: 100%;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px;
  margin-bottom: 12px;
  flex-wrap: wrap;
`

const TitleContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

const TextContainer = styled.div`
  z-index: 2;
  padding: 12px;
  max-width: 50%;
  display: flex;
  justify-content: center;
  text-align: center;
`

const SupportUsImage = styled.img`
  position: absolute;
  width: 100%;
  @media ${devices.tablet} {
    display: none;
  }
`

const ContentContainer = styled.div`
  z-index: 0;
  position: absolute;
  margin-top: ${props => props.marginTop}px;
  padding: 12px;
  width: 100%;
  box-sizing: border-box;
  padding-bottom: 128px;
  display: flex;
  flex-direction: column;
  @media ${devices.tablet} {
    margin-top: 12px;
    position: relative;
    justify-content: center;
    align-items: center;
  }
`

const Container = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`

SupportUs.propTypes = {}

export default SupportUs
