import { CHANGE_THEME } from '../actions/themes'

import { lightTheme } from '../config/themes'

const initialState = {
  currentTheme: lightTheme
}

export default (state = initialState, action) => {
  switch (action.type) {
    case CHANGE_THEME:
      return {
        ...state,
        currentTheme: action.payload
      }
    default:
      return state
  }
}
