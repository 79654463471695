import React, { useContext, useEffect } from 'react'
import styled, { ThemeContext } from 'styled-components'
import { AiOutlineLeft } from 'react-icons/ai'
import { useHistory } from 'react-router-dom'

import { CommonText } from '../texts'
import useScroll from '../../utils/useScroll'
import devices from '../../config/devices'

const HeaderBack = ({ label }) => {
  const history = useHistory()
  const theme = useContext(ThemeContext)
  const { scrollY } = useScroll()

  return (
    <>
      <HollowHeader></HollowHeader>
      <IconTouchable onClick={() => history.goBack()}>
        <AiOutlineLeft
          color={theme.iconColorSecondary}
          size={28}
        ></AiOutlineLeft>
      </IconTouchable>
      <HeaderBackContainer scrollY={scrollY}>
        <TextContainer scrollY={scrollY}>
          <CommonText>{label}</CommonText>
        </TextContainer>
      </HeaderBackContainer>
    </>
  )
}

const TextContainer = styled.div`
  display: ${props => (props.scrollY < 350 ? 'none' : 'flex')};
  transition: display 0.5s ease;

  @media ${devices.tablet} {
    position: relative;
  }
`

const HollowHeader = styled.div`
  width: 100%;
  height: 59px;
  @media ${devices.tablet} {
    display: none;
  }
`

const IconTouchable = styled.div`
  padding: 12px;
  position: fixed;
  top: 6px;
  z-index: 3;
  left: 0px;
  @media ${devices.tablet} {
    position: relative;
  }
`

const HeaderBackContainer = styled.div`
  display: flex;
  position: fixed;
  height: 59px;
  box-shadow: ${props =>
    props.scrollY < 350 ? 'none' : '0px 0px 12px #00000080'};
  width: 100%;
  justify-content: center;
  align-items: center;
  background-color: ${props =>
    props.scrollY < 300 ? 'transparent' : `${props.theme.backBar}`};
  transition: background-color 0.5s ease;

  @media ${devices.tablet} {
    display: none;
  }
`

HeaderBack.propTypes = {}

export default HeaderBack
