import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'

import enUS from './translations/en-US'
import frFR from './translations/fr-FR'

const resources = {
  en: enUS,
  fr: frFR
}

const detectorOptions = {
  // order and from where user language should be detected
  order: ['localStorage', 'navigator'],

  // keys or params to lookup language from
  lookupQuerystring: 'lng',
  lookupLocalStorage: 'i18nextLng',

  // cache user language on
  caches: ['localStorage']

  // optional expire and domain for set cookie
}

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    detection: detectorOptions,
    fallbackLng: 'en',

    interpolation: {
      escapeValue: false // react already safes from xss
    }
  })

export default i18n
