import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { PresentationText } from '../texts'
import Cover from '../cover'
import devices from '../../config/devices'

import albumDefault from '../../assets/images/albumDefault.png'
import artistDefault from '../../assets/images/artistDefault.png'
import surahDefault from '../../assets/images/surahDefault.png'

const returnDefaultImage = (img, type) => {
  if (img) return img
  else {
    switch (type) {
      case 'artist':
        return artistDefault
      case 'album':
        return albumDefault
      default:
        return surahDefault
    }
  }
}

const Carousel = ({ data = [], loading, label, type, isSearch }) => {
  if (loading) return <p>loading</p>
  return (
    <Container>
      <TextContainer>
        {data[0] ? <PresentationText>{label}</PresentationText> : null}
      </TextContainer>
      <CoverContainer>
        {data.map(carousel => (
          <Cover
            isSearch={isSearch}
            type={type}
            carousel={carousel}
            id={carousel.id}
            key={carousel.id}
            imageUri={returnDefaultImage(
              carousel.picture?.formats?.thumbnail?.url,
              type
            )}
            label={carousel.name}
          ></Cover>
        ))}
      </CoverContainer>
    </Container>
  )
}

const TextContainer = styled.div`
  padding-left: 12px;
`

const CoverContainer = styled.div`
  margin-top: 6px;
  padding-left: 12px;

  cursor: pointer;
  display: flex;
  flex-direction: row;
  overflow-x: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  ::-webkit-scrollbar {
    display: none;
  }
  @media ${devices.tablet} {
    flex-wrap: wrap;
  }
`

const Container = styled.div`
  padding: 6px;
  display: flex;
  flex-direction: column;
  @media ${devices.tablet} {
  }
`

Carousel.propTypes = {
  data: PropTypes.array,
  loading: PropTypes.bool,
  label: PropTypes.string,
  type: PropTypes.string
}

export default Carousel
