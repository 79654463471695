import React, { useEffect } from 'react'
import styled from 'styled-components'
import { AiOutlineSearch } from 'react-icons/ai'

import { SearchButton } from '../../components/buttons'
import { SearchTextTitle, SearchTextSubtitle } from '../../components/texts'
import useWindowSize from '../../utils/useWindowSize'
import handleNavigation from '../../utils/handleNavigation'
import { useTranslation } from 'react-i18next'
import tracker from '../../utils/tracker'

const Search = () => {
  const windowSize = useWindowSize()
  const [t] = useTranslation()
  useEffect(() => {
    tracker(window.location.pathname)
  }, [])
  return (
    <MobileSearch height={windowSize.height}>
      <Container height={windowSize.height}>
        <TextContainer>
          <TitleContainer>
            <SearchTextTitle>{t('search.greetings')}</SearchTextTitle>
          </TitleContainer>
          <SearchTextSubtitle>{t('search.instructions')}</SearchTextSubtitle>
        </TextContainer>
        <SearchBarContainer>
          <SearchButton
            onClick={() => handleNavigation('/search/recent')}
            iconSize={24}
            icon={AiOutlineSearch}
            label={t('search.search')}
          ></SearchButton>
        </SearchBarContainer>
      </Container>
    </MobileSearch>
  )
}

const TitleContainer = styled.div`
  padding-bottom: 12px;
`

const Container = styled.div`
  /* margin-top: ${props => `${props.height / 4}px`}; */
  width: 100%;
  height: ${props => `${props.height / 2}px`};

  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  border-bottom: none;
`

const SearchBarContainer = styled.div`
  padding: 12px;
  box-sizing: border-box;
  width: 100%;
  max-width: 800px;
  display: flex;
  justify-content: center;
`

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  text-align: center;
  padding-bottom: 24px;
`

const MobileSearch = styled.div`
  display: flex;
  align-items: flex-end;
  width: 100%;
  flex-direction: column;
  background-image: linear-gradient(
      230deg,
      #5e24d4a0,
      rgba(255, 0, 0, 0) 60.71%
    ),
    linear-gradient(140deg, #3164f4a0, rgba(0, 255, 0, 0) 60.71%),
    linear-gradient(0deg, rgba(0, 0, 0, 1), rgba(01, 20, 20, 1) 100%);
`

export default Search
