import SearchBar from '../../components/searchBar'
import styled from 'styled-components'
import { useEffect, useRef, useState } from 'react'
import tracker from '../../utils/tracker'
import { useLazyQuery } from '@apollo/client'
import { search } from '../../graphql/queries'
import { useTranslation } from 'react-i18next'
import getLocale from '../../utils/getLocale'
import Carousel from '../../components/carousel'
import Playlist from '../../components/playlist'
import RecentSearch from '../../components/recentSearch'
import Loader from '../../components/loader'

const Recent = () => {
  const { t } = useTranslation()
  const searchRef = useRef(null)
  const [searchInput, setSearchInput] = useState('')
  const [launchSearch, { loading, error, data }] = useLazyQuery(search.SEARCH)

  useEffect(() => {
    if (searchInput.length === 0) return

    clearTimeout(searchRef.current)

    searchRef.current = setTimeout(() => {
      launchSearch({
        variables: {
          locale: getLocale(),
          search: searchInput
        }
      })
    }, 400)

    return () => {
      clearTimeout(searchRef.current)
    }
  }, [searchInput])

  useEffect(() => {
    tracker(window.location.pathname)
  }, [])

  return (
    <Container>
      <SearchBar search={searchInput} setSearch={setSearchInput}></SearchBar>
      {loading ? <Loader></Loader> : null}
      {error ? <p>error</p> : null}
      {searchInput.length === 0 ? (
        <RecentSearch></RecentSearch>
      ) : (
        <>
          <Carousel
            type='artist'
            label={t('search.reciters')}
            loading={loading}
            data={data?.artists}
            isSearch={true}
          ></Carousel>
          <Playlist
            displayArtist={true}
            label={t('search.audios')}
            audios={data?.audios}
            loading={loading}
            isSearch={true}
          ></Playlist>
        </>
      )}

      {/* Mettre le message comme dans spotify  // et faire composant de recherche + resultat recent */}
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`
export default Recent
