import { createGlobalStyle } from 'styled-components'

import {
  RobotoBold,
  RobotoBoldItalic,
  RobotoRegular,
  RobotoBlackItalic,
  RobotoBlack
} from '../assets/fonts/roboto'

const GlobalStyle = createGlobalStyle`
  @font-face {
     font-family: 'Roboto';
     src: url(${RobotoRegular}) format("truetype");
     font-weight: normal;
     font-style: normal;
  };
 @font-face {
     font-family: 'Roboto';
     src: url(${RobotoBold}) format("truetype");
     font-weight: bold;
     font-style: normal;
  };
  @font-face {
     font-family: 'Roboto';
     src: url(${RobotoBoldItalic}) format("truetype");
     font-weight: bold;
     font-style: italic;
  };
  @font-face {
     font-family: 'Roboto';
     src: url(${RobotoBlack}) format("truetype");
     font-weight: bolder;
     font-style: normal;
  };
  @font-face {
     font-family: 'Roboto';
     src: url(${RobotoBlackItalic}) format("truetype");
     font-weight: bolder;
     font-style: italic;
  };
  span {
    font-family: 'Roboto'
  }
  body {
    background-color: ${props => props.theme.primary};
    overscroll-behavior: none;
  
  }
`

export default GlobalStyle
