import React, { useEffect } from 'react'
// import PropTypes from 'prop-types'
import styled from 'styled-components'
import OptionsRow from '../../components/optionsRow'
import { useTranslation } from 'react-i18next'
import handleNavigation from '../../utils/handleNavigation'
import OptionsHeader from '../../components/optionsHeader'
import tracker from '../../utils/tracker'
import HeaderBack from '../../components/headerBack'

const Settings = () => {
  const [t] = useTranslation()
  useEffect(() => {
    tracker(window.location.pathname)
  }, [])

  const sendMail = (e, mailto) => {
    e.preventDefault()
    window.location = mailto
  }

  return (
    <Container>
      <HeaderBack></HeaderBack>
      <OptionsHeader label={t('settings.settings')}></OptionsHeader>
      <OptionsContainer>
        {/* <OptionsRow
          onClick={() => handleNavigation('/settings/themes')}
          label={t('settings.theme')}
        ></OptionsRow> */}
        <OptionsRow
          onClick={() => handleNavigation('/settings/language')}
          label={t('settings.language')}
        ></OptionsRow>
        <OptionsRow
          onClick={e => sendMail(e, t('settings.mailTo'))}
          label={t('settings.mail')}
        ></OptionsRow>
      </OptionsContainer>
    </Container>
  )
}

const OptionsContainer = styled.div``

const Container = styled.div`
  display: flex;
  min-height: 50vh;
  width: 100%;
  flex-direction: column;
  background-image: ${props => `linear-gradient(
      200deg,
      ${props.theme.linearPrimary},
      rgba(255, 0, 0, 0) 60.71%
    ),
    linear-gradient(160deg, ${props.theme.linearSecondary}, rgba(0, 255, 0, 0) 60.71%),
    linear-gradient(0deg, rgba(0, 0, 0, 1), rgba(01, 20, 20, 1) 100%)`};
`

Settings.propTypes = {}

export default Settings
