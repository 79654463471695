export const ADD_TO_RECENT_ARTIST = 'ADD_TO_RECENT_ARTIST'
export const ADD_TO_RECENT_AUDIOS = 'ADD_TO_RECENT_AUDIOS'

export const addToRecentArtist = payload => ({
  type: ADD_TO_RECENT_ARTIST,
  payload
})

export const addToRecentAudios = payload => ({
  type: ADD_TO_RECENT_AUDIOS,
  payload
})
