import React from 'react'
import PropTypes from 'prop-types'
import { OptionsTitle } from '../texts'
import styled from 'styled-components'

const OptionsHeader = ({ label }) => {
  return (
    <TitleContainer>
      <OptionsTitle>{label}</OptionsTitle>
    </TitleContainer>
  )
}

const TitleContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 24px;
  box-sizing: border-box;
`

OptionsHeader.propTypes = {
  label: PropTypes.string
}

export default OptionsHeader
