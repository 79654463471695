import React, { useContext, useEffect, useState } from 'react'
import styled, { ThemeContext } from 'styled-components'
import { AiOutlineHeart, AiFillHeart } from 'react-icons/ai'
import { BsFillPauseFill, BsFillPlayFill } from 'react-icons/bs'
import { AudioText, TitleAudioText } from '../texts'
import handleArtistsDisplay from '../../utils/handleArtistsDisplay'
import PropTypes from 'prop-types'
import addOrRemoveAudioToFavorite from '../../utils/addOrRemoveAudioToFavorite'
import { useDispatch, useSelector } from 'react-redux'
import devices from '../../config/devices'
import surahDefault from '../../assets/images/surahDefault.png'
import ClickableProgressionBar from '../clickableProgressionBar'
import { FaRandom } from 'react-icons/fa'

import { IoPlaySkipForwardSharp, IoPlaySkipBackSharp } from 'react-icons/io5'
import { AiFillPlayCircle, AiOutlinePauseCircle } from 'react-icons/ai'
import { RiRepeatFill, RiRepeatOneFill } from 'react-icons/ri'

const AudioPlayerDesktop = ({
  audioRef,
  currentSong,
  progression,
  handleIsRandom,
  getPreviousSong,
  getNextSong,
  mode,
  handleRepeat,
  timeAudio,
  isRandom,
  handlePlay,
  isPaused
}) => {
  if (!audioRef.current || !currentSong.id) return null
  const dispatch = useDispatch()
  const theme = useContext(ThemeContext)
  const [isFavorite, setIsFavorite] = useState(false)
  const favoriteAudios = useSelector(state => state.favorite.audios)
  const { album } = currentSong

  useEffect(() => {
    const checkFavorite = favoriteAudios.filter(
      fav => fav.id === currentSong.id
    )
    if (checkFavorite[0]) {
      setIsFavorite(true)
    } else {
      setIsFavorite(false)
    }
  }, [currentSong])

  const srcImage = album?.picture?.formats?.thumbnail?.url
    ? album?.picture?.formats?.thumbnail?.url
    : surahDefault

  return (
    <Container>
      <AudioPlayerContainer>
        <LeftContainer>
          <Avatar src={srcImage}></Avatar>

          <TextContainer>
            <TitleContainer>
              <TitleAudioText>{currentSong.title}</TitleAudioText>
            </TitleContainer>
            <ArtistContainer>
              <TitleAudioText>
                {handleArtistsDisplay(currentSong.artists)}
              </TitleAudioText>
            </ArtistContainer>
          </TextContainer>
          <IconTouchable
            onClick={() =>
              addOrRemoveAudioToFavorite({
                data: currentSong,
                isFavorite,
                setIsFavorite,
                dispatch,
                type: 'audios'
              })
            }
          >
            {isFavorite ? (
              <AiFillHeart color={theme.iconColor} size={24}></AiFillHeart>
            ) : (
              <AiOutlineHeart
                color={theme.iconColor}
                size={24}
              ></AiOutlineHeart>
            )}
          </IconTouchable>
        </LeftContainer>
        <MiddleContainer>
          <PlayerContainer>
            <UpperPart>
              <IconTouchable onClick={handleIsRandom}>
                <FaRandom
                  color={isRandom ? theme.controlSelected : theme.iconColor}
                  size={16}
                ></FaRandom>
              </IconTouchable>
              <IconTouchable
                onClick={() => getPreviousSong({ userInteraction: true })}
              >
                <IoPlaySkipBackSharp
                  color={theme.iconColor}
                  size={24}
                ></IoPlaySkipBackSharp>
              </IconTouchable>
              <IconTouchable onClick={handlePlay}>
                {isPaused ? (
                  <AiFillPlayCircle
                    color={theme.iconColor}
                    size={48}
                  ></AiFillPlayCircle>
                ) : (
                  <AiOutlinePauseCircle
                    color={theme.iconColor}
                    size={48}
                  ></AiOutlinePauseCircle>
                )}
              </IconTouchable>
              <IconTouchable
                onClick={() => getNextSong({ userInteraction: true })}
              >
                <IoPlaySkipForwardSharp
                  color={theme.iconColor}
                  size={24}
                ></IoPlaySkipForwardSharp>
              </IconTouchable>
              {mode === 'normal' || mode === 'repeatAll' ? (
                <IconTouchable onClick={() => handleRepeat(mode)}>
                  <RiRepeatFill
                    color={
                      mode === 'repeatAll'
                        ? theme.controlSelected
                        : theme.iconColor
                    }
                    size={18}
                  ></RiRepeatFill>
                </IconTouchable>
              ) : null}
              {mode === 'repeatOne' ? (
                <IconTouchable onClick={() => handleRepeat(mode)}>
                  <RiRepeatOneFill
                    color={theme.controlSelected}
                    size={32}
                  ></RiRepeatOneFill>
                </IconTouchable>
              ) : null}
            </UpperPart>
            <LowerPart>
              <AudioText>{timeAudio.progression}</AudioText>
              <ClickableProgressionBar
                progression={progression}
                goal={audioRef?.current?.duration}
                audioRef={audioRef}
              ></ClickableProgressionBar>
              <AudioText>{timeAudio.goal}</AudioText>
            </LowerPart>
          </PlayerContainer>
        </MiddleContainer>
        <RightContainer></RightContainer>
      </AudioPlayerContainer>
    </Container>
  )
}

const UpperPart = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`
const LowerPart = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`

const MiddleContainer = styled.div`
  display: flex;
  padding: 12px;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const PlayerContainer = styled.div`
  max-width: 600px;
  width: 100%;
`

const LeftContainer = styled.div`
  display: flex;
  padding: 12px;
  max-width: 250px;
  width: 100%;
  align-items: center;
  justify-content: center;
`

const RightContainer = styled.div`
  display: flex;
  padding: 12px;
  max-width: 150px;
  width: 100%;
`

const Container = styled.div`
  display: none;

  @media ${devices.tablet} {
    display: flex;
  }
`

const Avatar = styled.img`
  width: 60px;
  height: 60px;
`

const TitleContainer = styled.div``
const ArtistContainer = styled.div``

const TextContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  padding-left: 12px;
`

const IconTouchable = styled.div`
  padding: 12px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
`

const AudioPlayerContainer = styled.div`
  display: flex;
  background-color: ${props => props.theme.navBar};
  border-bottom: 1px solid ${props => props.theme.secondary};
  width: 100%;
`

AudioPlayerDesktop.propTypes = {
  audioRef: PropTypes.any,
  currentSong: PropTypes.object,
  openModal: PropTypes.func,
  progression: PropTypes.object,
  setProgression: PropTypes.func,
  handleIsRandom: PropTypes.func,
  getPreviousSong: PropTypes.func,
  getNextSong: PropTypes.func,
  mode: PropTypes.string,
  handleRepeat: PropTypes.func,
  timeAudio: PropTypes.object,
  isRandom: PropTypes.bool,
  handlePlay: PropTypes.func,
  isPaused: PropTypes.bool
}

export default AudioPlayerDesktop
