import { gql } from '@apollo/client'

export const GET_ARTISTS = gql`
  query Artists($locale: String, $limit: Int, $start: Int) {
    artists(locale: $locale, limit: $limit, start: $start) {
      id
      name
      picture {
        formats
      }
    }
  }
`

export const GET_ONE_ARTIST = gql`
  query Artists($locale: String, $id: String, $albumName: String) {
    artists(locale: $locale, where: { id: $id }) {
      id
      name
      albums {
        name
        picture {
          formats
        }
        audios(
          where: { album: { name: $albumName } }
          limit: 114
          sort: "trackOrder:ASC"
        ) {
          album {
            name
            picture {
              formats
            }
          }
          id
          title
          titlePhonetic
          trackOrder
          artists {
            name
          }
          url
        }
        id
      }
      picture {
        formats
      }
    }
  }
`

export const GET_HOME_PAGE = gql`
  query Artists($locale: String, $limit: Int) {
    artists(locale: $locale, limit: $limit, sort: "created_at:DESC") {
      id
      name
      picture {
        formats
      }
    }
    medina(locale: $locale) {
      artists {
        id
        name
        picture {
          formats
        }
      }
    }
    mekka(locale: $locale) {
      artists {
        id
        name
        picture {
          formats
        }
      }
    }
  }
`
