import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { AiOutlineArrowLeft, AiOutlineClose } from 'react-icons/ai'
import { useContext } from 'react'
import { ThemeContext } from 'styled-components'
import handleNavigation from '../../utils/handleNavigation'
import { useTranslation } from 'react-i18next'

const SearchBar = ({ search, setSearch }) => {
  const theme = useContext(ThemeContext)
  const inputRef = useRef(null)
  const [t] = useTranslation()
  useEffect(() => {
    resetInput()
  }, [])
  const resetInput = () => {
    inputRef.current.focus()
    setSearch('')
  }
  return (
    <>
      <HollowContainer></HollowContainer>
      <Container>
        <BackContainer onClick={() => handleNavigation('/search')}>
          <AiOutlineArrowLeft
            color={theme.searchBarIcon}
            size={24}
          ></AiOutlineArrowLeft>
        </BackContainer>
        <SearchInput
          ref={ref => (inputRef.current = ref)}
          value={search}
          onChange={e => setSearch(e.target.value)}
          placeholder={t('search.search')}
        ></SearchInput>
        <CloseContainer onClick={resetInput}>
          <AiOutlineClose
            color={theme.searchBarIcon}
            size={24}
          ></AiOutlineClose>
        </CloseContainer>
      </Container>
    </>
  )
}

const SearchInput = styled.input`
  width: 100%;
  background-color: transparent;
  border: none;
  outline: none;
  color: ${props => props.theme.searchBarText};
  font-size: 16px;
  font-weight: 600;
  ::placeholder {
    color: ${props => props.theme.searchBarText};
    font-size: 16px;
  }
`

const CloseContainer = styled.div`
  display: flex;
  padding: 12px;
`
const BackContainer = styled.div`
  display: flex;
  padding: 12px;
`

const Container = styled.div`
  display: flex;
  width: 100%;
  height: 48px;
  box-sizing: border-box;
  position: fixed;
  background-color: ${props => props.theme.searchBar};
`
const HollowContainer = styled.div`
  width: 100%;
  height: 48px;
  box-sizing: border-box;
`

SearchBar.propTypes = {
  search: PropTypes.string,
  setSearch: PropTypes.func
}

export default SearchBar
