import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import devices from '../../config/devices'
import { MenuText } from '../texts'

const NavButton = ({ icon: Icon, label, iconColor, iconSize, onClick }) => {
  return (
    <ButtonContainer onClick={onClick}>
      <Icon color={iconColor} size={iconSize}></Icon>
      <MenuText>{label}</MenuText>
    </ButtonContainer>
  )
}

const ButtonContainer = styled.div`
  cursor: pointer;
  background-color: transparent;
  display: flex;
  flex-direction: column;
  padding: 3px 12px;
  align-items: center;
  box-sizing: border-box;
  @media ${devices.tablet} {
    flex-direction: row;
    padding: 12px 0px;
    margin-top: 12px;
    padding-left: 12px;
    width: 100%;
  }
`

NavButton.propTypes = {
  icon: PropTypes.any,
  label: PropTypes.string,
  iconColor: PropTypes.string,
  iconSize: PropTypes.number,
  onClick: PropTypes.func
}

export default NavButton
