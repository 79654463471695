import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { PlaylistSubText, PlaylistText, TrackOrderSubText } from '../texts'
import handleArtistDisplay from '../../utils/handleArtistsDisplay'

const PlaylistRow = ({
  title,
  titlePhonetic,
  trackOrder,
  artists,
  handleClick,
  displayArtist
}) => {
  return (
    <PlaylistRowContainer onClick={handleClick}>
      <TrackOrderContainer>
        <TrackOrderSubText>{trackOrder + 1}</TrackOrderSubText>
      </TrackOrderContainer>
      <TextContainer>
        <PlaylistText>{title}</PlaylistText>
        {/* {titlePhonetic ? ( */}
        <PlaylistSubText>
          {titlePhonetic} {titlePhonetic && displayArtist ? '- ' : null}{' '}
          {displayArtist ? handleArtistDisplay(artists) : null}
        </PlaylistSubText>
        {/* ) : null} */}
        <PlaylistSubText></PlaylistSubText>
      </TextContainer>
      <TextContainer></TextContainer>
    </PlaylistRowContainer>
  )
}

const TrackOrderContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0px 36px 0px 24px;
`

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const PlaylistRowContainer = styled.div`
  cursor: pointer;
  display: flex;
  margin-top: 12px;
  padding: 12px;
  background-color: ${props => props.theme.playlistRow};
  border-radius: 4px;
  min-height: 35px;
  align-items: center;
  width: 100%;
  max-width: 800px;
`
PlaylistRow.propTypes = {
  title: PropTypes.string,
  titlePhonetic: PropTypes.string,
  trackOrder: PropTypes.number,
  url: PropTypes.string,
  artists: PropTypes.array,
  handleClick: PropTypes.func,
  displayArtist: PropTypes.func
}

export default PlaylistRow
