export default {
  translation: {
    navBar: {
      home: 'Home',
      search: 'Search',
      favorite: 'Favorite'
    },
    errorHandler: {
      apiError: 'Une erreur est survenue'
    },
    paypal: {
      currentCurrency: 'USD',
      donationValue: [
        { title: 'Muslimin Play - 360H', currency: '$', value: '3.00' },
        { title: 'Muslimin Play - 600H', currency: '$', value: '5.00' },
        { title: 'Muslimin Play - 1200H', currency: '$', value: '10.00' },
        { title: 'Muslimin Play - 2400H', currency: '$', value: '20.00' },
        { title: 'Muslimin Play - 6000H', currency: '$', value: '50.00' },
        { title: "L'homme qui valait 12000H", currency: '$', value: '100.00' }
      ]
    },
    search: {
      greetings: 'Salam Alaykum',
      instructions: 'Search a surah or a reciter',
      search: 'Search',
      reciters: 'Reciters found',
      recentReciters: 'recent Reciters',
      audios: 'Track found',
      recentAudios: 'Recent Track',
      noResult: 'No results',
      noSearch: 'Search in Muslimin Play',
      noSearchSubtitle: 'Search for your favorite Surah and Reciters'
    },
    favorite: {
      reciters: 'Favorites Reciters',
      audios: 'Favorites Track'
    },
    home: {
      lastReciters: 'Latest Reciters',
      supportUs: 'Support Us',
      mekka: "Mekka's Reciter",
      medina: "Medina's Reciter",
      supportUsSubText: 'Look at the many way you can help Muslimin Play',
      allReciters: 'See all Reciters',
      allRecitersSubText: 'Go throught all of our reciters',
      welcome: 'Welcome to Muslimin Play'
    },

    supportUs: {
      support: 'SUPPORT THE MUSLIMIN PLAY TEAM',
      suportText:
        "Every dollar given is more or less 120h of streaming on Muslimin Play, you're help allows us to keep the website free for all, and take care of the others fee we have",
      oneTime: 'ONE TIME DONATION',
      monthly: 'MONTHLY DONATION'
    },
    allArtists: {
      header: 'All reciters',
      noMore: 'No more reciters available'
    },
    artist: {
      quranSearch: 'The Holy Quran'
    },
    settings: {
      settings: 'Settings',
      theme: 'Theme',
      language: 'Language',
      mail: 'Report an issue',
      mailTo:
        'mailto:contact@muslimin-play.com?subject=I found an issue in the app',
      pageTheme: {
        darkTheme: 'Dark theme',
        lightTheme: 'Light theme'
      },
      pageLanguage: {
        french: 'French',
        english: 'English'
      }
    }
  }
}
