import { useQuery } from '@apollo/client'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import InfiniteScroll from 'react-infinite-scroll-component'
import styled from 'styled-components'
import ArtistRow from '../../components/artistRow'
import HeaderBack from '../../components/headerBack'
import Loader from '../../components/loader'
import { CommonText } from '../../components/texts'
import { artists } from '../../graphql/queries'
import getLocale from '../../utils/getLocale'

const AllArtists = () => {
  const { t } = useTranslation()
  const [allArtists, setAllArtists] = useState([])
  const [page, setPage] = useState(0)
  const [hasMore, setHasMore] = useState(true)

  //TODO:CREER UN loader
  const { loading, error, data, refetch, networkStatus, fetchMore } = useQuery(
    artists.GET_ARTISTS,
    {
      variables: { locale: getLocale(), limit: 10, start: 10 * page }
    }
  )
  useEffect(() => {
    if (!data?.artists) return
    if (data.artists.length === 0) {
      setHasMore(false)
      return
    }
    setAllArtists([...allArtists, ...data?.artists])
  }, [data?.artists])

  useEffect(() => {
    if (page === 0) return
    fetchMore({
      variables: { locale: getLocale(), limit: 10, start: 10 * page }
    })
  }, [page])

  return (
    <Container>
      <HeaderBack label={t('allArtists.header')}></HeaderBack>
      <InfiniteScroll
        dataLength={allArtists.length} //This is important field to render the next data
        next={() => setPage(page + 1)}
        hasMore={hasMore}
        loader={<Loader></Loader>}
        endMessage={
          <SeeMore>
            <CommonText>{t('allArtists.noMore')}</CommonText>
          </SeeMore>
        }
        // below props only if you need pull down functionality
        // refreshFunction={refetch}
        // pullDownToRefresh
        // pullDownToRefreshThreshold={50}
        // pullDownToRefreshContent={
        //   <h3 style={{ textAlign: 'center' }}>&#8595; Pull down to refresh</h3>
        // }
        // releaseToRefreshContent={
        //   <h3 style={{ textAlign: 'center' }}>&#8593; Release to refresh</h3>
        // }
      >
        {allArtists.map(artist => (
          <ArtistRow key={artist.id} artist={artist}></ArtistRow>
        ))}
      </InfiniteScroll>
    </Container>
  )
}

const SeeMore = styled.div`
  display: flex;
  justify-content: center;
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0px 12px;
  width: 100%;
  background-image: ${props => `linear-gradient(
      200deg,
      ${props.theme.linearPrimary},
      rgba(255, 0, 0, 0) 20.71%
    ),
    linear-gradient(160deg, ${props.theme.linearSecondary}, rgba(0, 255, 0, 0) 20.71%),
    linear-gradient(0deg, rgba(0, 0, 0, 1), rgba(01, 20, 20, 1) 100%)`};
`

export default AllArtists
