import _ from 'lodash'

import {
  CHANGE_CURRENT_SONG,
  CHANGE_CURRENT_PLAYLIST,
  SWITCH_ISRANDOM,
  CHANGE_MODE
} from '../actions/audioPlayer'

const initialState = {
  mode: 'normal',
  isRandom: false,
  displayPlaylist: {
    artists: [],
    title: '',
    audios: [],
    albums: {}
  },
  currentPlaylist: {
    artists: [],
    title: '',
    audios: [],
    albums: {}
  },
  currentSong: {
    id: null,
    url: null,
    artists: [],
    title: '',
    picture: {}
  }
}

const setPlaylist = (state, playlist, isRandom) => {
  if (isRandom) {
    const randomArray = _.shuffle(playlist.audios)
    const removedSongArray = _.filter(
      randomArray,
      audio => audio.id !== state.currentSong.id
    )
    removedSongArray.unshift(state.currentSong)
    return {
      ...playlist,
      audios: removedSongArray
    }
  } else {
    return playlist
  }
}

export default (state = initialState, action) => {
  switch (action.type) {
    case CHANGE_CURRENT_SONG:
      return {
        ...state,
        currentSong: action.payload
      }
    case CHANGE_CURRENT_PLAYLIST:
      return {
        ...state,
        currentPlaylist: setPlaylist(state, action.payload, state.isRandom),
        displayPlaylist: action.payload
      }
    case SWITCH_ISRANDOM:
      return {
        ...state,
        isRandom: !state.isRandom,
        currentPlaylist: setPlaylist(
          state,
          state.displayPlaylist,
          !state.isRandom
        )
      }
    case CHANGE_MODE:
      return {
        ...state,
        mode: action.payload
      }
    default:
      return state
  }
}
