import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import styled, { ThemeContext } from 'styled-components'
import { OptionsRowText } from '../texts'
import { AiOutlineRight } from 'react-icons/ai'

const OptionsRow = ({ label, onClick }) => {
  const theme = useContext(ThemeContext)

  return (
    <Container onClick={onClick}>
      <OptionsRowText>{label}</OptionsRowText>
      <RightContainer>
        <AiOutlineRight
          color={theme.iconColorSecondary}
          size={24}
        ></AiOutlineRight>
      </RightContainer>
    </Container>
  )
}

const RightContainer = styled.div``

const Container = styled.div`
  padding: 12px;
  margin-bottom: 6px;
  display: flex;
  justify-content: space-between;
`

OptionsRow.propTypes = {
  label: PropTypes.string,
  onClick: PropTypes.func
}

export default OptionsRow
