import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const ProgressionBar = ({ goal, progression }) => {
  const [progress, setProgress] = useState(0)
  useEffect(() => {
    const ratio = (progression / goal) * 100
    setProgress(ratio)
  }, [progression])
  return (
    <ProgressionBarContainer>
      <Bar progress={progress}></Bar>
    </ProgressionBarContainer>
  )
}

const Bar = styled.div.attrs(props => ({
  style: {
    width: props.progress ? `${props.progress}%` : '0%'
  }
}))`
  height: 3px;
  background-color: white;
`

const ProgressionBarContainer = styled.div`
  background-color: #00000030;
  width: 100%;
  height: 3px;
`

ProgressionBar.propTypes = {
  goal: PropTypes.number,
  progression: PropTypes.number
}

export default ProgressionBar
