import { gql } from '@apollo/client'

export const SEARCH = gql`
  query Search($locale: String, $search: String) {
    artists(locale: $locale, where: { name_contains: $search }) {
      id
      name
      picture {
        formats
      }
    }
    audios(
      locale: $locale
      where: {
        _or: [{ title_contains: $search }, { titlePhonetic_contains: $search }]
      }
      limit: 20
    ) {
      album {
        name
        picture {
          formats
        }
      }
      id
      title
      titlePhonetic
      trackOrder
      artists {
        name
      }
      url
    }
  }
`
