import allTheActions from '../actions'

const addOrRemoveAudioToFavorite = ({
  data,
  isFavorite,
  setIsFavorite,
  dispatch,
  type
}) => {
  if (!isFavorite) {
    setIsFavorite(true)
    dispatch(allTheActions.favorite.addFavorite({ type: type, data: data }))
  } else {
    setIsFavorite(false)
    dispatch(allTheActions.favorite.removeFavorite({ type: type, data: data }))
  }
}

export default addOrRemoveAudioToFavorite
