import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const SearchButton = ({ icon: Icon, iconSize, iconColor, label, onClick }) => {
  return (
    <StyledButton onClick={onClick}>
      <Content>
        <Icon size={iconSize} color={iconColor}></Icon>
        <Label>{label}</Label>
      </Content>
    </StyledButton>
  )
}

const Label = styled.span`
  font-size: 16px;
  font-weight: 600;
  margin-left: 6px;
`

const Content = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

const StyledButton = styled.button`
  width: 100%;
  height: 48px;
  border-radius: 4px;
  border: 1px solid transparent;
  background-color: #ffffff;
  box-shadow: 0px 5px 10px #0000000f;
`

SearchButton.propTypes = {
  icon: PropTypes.any,
  iconSize: PropTypes.number,
  iconColor: PropTypes.string,
  label: PropTypes.string,
  onClick: PropTypes.func
}

export default SearchButton
