import React from 'react'
import { Provider } from 'react-redux'

import Routes from './config/routes'
import { PersistGate } from 'redux-persist/integration/react'

import './config/translationConfig'
import { persistor, store } from './config/store'
import { ApolloProvider } from '@apollo/client'
import apolloClient from './utils/apolloClient'
import { PayPalScriptProvider, PayPalButtons } from '@paypal/react-paypal-js'
import { useTranslation } from 'react-i18next'

const App = () => {
  const { t } = useTranslation()
  const initialOptions = {
    'client-id': process.env.REACT_APP_PAYPAL_SECRET,
    currency: t('paypal.currentCurrency'),
    intent: 'capture'
  }
  return (
    <ApolloProvider client={apolloClient}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <PayPalScriptProvider options={initialOptions}>
            <Routes />
          </PayPalScriptProvider>
        </PersistGate>
      </Provider>
    </ApolloProvider>
  )
}

export default App
