import { usePayPalScriptReducer } from '@paypal/react-paypal-js'
import i18next from 'i18next'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import HeaderBack from '../../components/headerBack'
import OptionsHeader from '../../components/optionsHeader'
import OptionsRow from '../../components/optionsRow'
import tracker from '../../utils/tracker'

const Language = () => {
  const [t] = useTranslation()
  const history = useHistory()
  const [{ options }, dispatch] = usePayPalScriptReducer()

  useEffect(() => {
    tracker(window.location.pathname)
  }, [])
  const onCurrencyChange = value => {
    dispatch({
      type: 'resetOptions',
      value: {
        ...options,
        currency: value
      }
    })
  }

  const handleLanguageChange = language => {
    i18next.changeLanguage(language)
    const newCurrencie = i18next.t('paypal.currentCurrency')
    onCurrencyChange(newCurrencie)
    history.goBack()
  }
  useEffect(() => {
    tracker(window.location.pathname)
  }, [])

  return (
    <Container>
      <HeaderBack></HeaderBack>
      <OptionsHeader label={t('settings.language')}></OptionsHeader>
      <OptionsRow
        onClick={() => handleLanguageChange('en')}
        label={t('settings.pageLanguage.english')}
      ></OptionsRow>
      <OptionsRow
        onClick={() => handleLanguageChange('fr')}
        label={t('settings.pageLanguage.french')}
      ></OptionsRow>
    </Container>
  )
}

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  background-image: ${props => `linear-gradient(
      200deg,
      ${props.theme.linearPrimary},
      rgba(255, 0, 0, 0) 60.71%
    ),
    linear-gradient(160deg, ${props.theme.linearSecondary}, rgba(0, 255, 0, 0) 60.71%),
    linear-gradient(0deg, rgba(0, 0, 0, 1), rgba(01, 20, 20, 1) 100%)`};
`

export default Language
