import React from 'react'
import PropTypes from 'prop-types'
import { ArtistText } from '../texts'
import styled from 'styled-components'
import devices from '../../config/devices'
import { useHistory } from 'react-router-dom'
import allTheActions from '../../actions'
import { useDispatch } from 'react-redux'

const Cover = ({ label, imageUri, type, id, isSearch, carousel }) => {
  const history = useHistory()
  const dispatch = useDispatch()

  const handleNavigation = (type, id) => {
    if (isSearch) {
      dispatch(
        allTheActions.recent.addToRecentArtist({
          ...carousel
        })
      )
    }
    history.push(`/${type}/${id}`)
  }

  return (
    <CoverContainer onClick={() => handleNavigation(type, id)}>
      <CoverImage src={imageUri}></CoverImage>
      <TextContainer>
        <ArtistText>{label}</ArtistText>
      </TextContainer>
    </CoverContainer>
  )
}

const TextContainer = styled.div`
  margin-top: 3px;
  text-align: center;
`

const CoverContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 12px;
  align-items: center;
  @media ${devices.tablet} {
    flex-wrap: wrap;
    margin-top: 6px;
  }
`

const CoverImage = styled.img`
  width: 134px;
  height: 134px;
  object-fit: cover;
  border-radius: 2px;
`

Cover.propTypes = {
  label: PropTypes.string,
  imageUri: PropTypes.string,
  type: PropTypes.string,
  id: PropTypes.string
}

export default Cover
