import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { CommonText } from '../texts'
import { PayPalButtons } from '@paypal/react-paypal-js'

const PaypalButton = ({ item, currency }) => {
  return (
    <UniquePaymentContainer>
      <UniqueTitleContainer>
        <CommonText>{item.title}</CommonText>
      </UniqueTitleContainer>
      <UniqueAmountContainer>
        <CommonText>
          {item.value} {item.currency}
        </CommonText>
      </UniqueAmountContainer>
      <PayPalButtons
        style={{ layout: 'horizontal' }}
        onError={err => {
          console.error(err)
        }}
        onApprove={(data, actions) => {
          actions.order.capture()
        }}
        createOrder={(data, actions) => {
          return actions.order.create({
            purchase_units: [
              {
                description: 'Muslimin Play Support',
                amount: {
                  value: item.value,
                  currency_code: currency,
                  breakdown: {
                    item_total: {
                      currency_code: currency,
                      value: item.value
                    }
                  }
                },
                items: [
                  {
                    unit_amount: {
                      currency_code: currency,
                      value: item.value
                    },
                    quantity: '1',
                    name: item.title
                  }
                ]
              }
            ]
          })
        }}
      />
    </UniquePaymentContainer>
  )
}

const UniqueTitleContainer = styled.div``
const UniqueAmountContainer = styled.div`
  margin: 12px 0px;
`

const UniquePaymentContainer = styled.div`
  background-color: ${props => props.theme.paypal};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  padding: 12px;
  max-width: 160px;
  height: 100px;
  text-align: center;
`

PaypalButton.propTypes = {
  item: PropTypes.object,
  currency: PropTypes.string
}

export default PaypalButton
